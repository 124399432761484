import React, { Fragment } from 'react';

import useLearnToSwimRegistrationPrimaryContact from './UseLearnToSwimRegistrationPrimaryContact';

import LearnToSwimRegistrationLeftNav from '../components/leftNav/LearnToSwimRegistrationLeftNav';
import RegistrationPrimaryContactForm from '../components/forms/RegistrationPrimaryContactForm';
import { PRIMARY_CONTACT_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import LearnToSwimRegistrationStaffDupPopup from '../components/dialogs/LearnToSwimRegistrationStaffDupPopup';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimRegistrationPrimaryContact = () => {
  const {
    isLoading,
    dupPersons,
    ltsProviderRegistrationState,
    displayDupModalState,
    newPersonState,
    onDupModalCancelClicked,
    onPersonConfirmed,
    onSubmitFormCallback,
    onBackClicked
  } = useLearnToSwimRegistrationPrimaryContact();

  return (
    <Fragment>
      <LearnToSwimRegistrationLeftNav viewName={PRIMARY_CONTACT_NAME} viewTitle={'Primary Contact'}>
        <RegistrationPrimaryContactForm
          providerObj={ltsProviderRegistrationState.objData}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={onBackClicked} />
        {displayDupModalState === true &&
          <LearnToSwimRegistrationStaffDupPopup
            dupArrayData={dupPersons}
            newPersonObj={newPersonState}
            onPersonConfirmed={onPersonConfirmed}
            onCancelClicked={onDupModalCancelClicked} />
        }
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isLoading} />
      </LearnToSwimRegistrationLeftNav>
    </Fragment>
  );
};

export default LearnToSwimRegistrationPrimaryContact;