import Constants from "../../../../common/utils/Constants";

export const PROVIDER_OBJ = {
  entityName: '',
  tradeName: '',
  taxId: '',
  website: '',
  logo: '',
  offersFinancialAssistance: '', //bool
  currentLTSGrantRecipient: '', //bool
  affiliatedWithUsasClub: '', //bool
  learnToSwimCurriculumId: Constants.DEFAULT_ID,
  usasClubId: '',
  contacts: [
    {
      personId: '',
      firstName: '',
      lastName: '',
      birthDate: Constants.BLANK_DATE_STRING,
      email: '',
      phoneNumber: ''
    }
  ],
  facilities: []
};

const LearnToSwimRegistrationConstants = {
  PROVIDER_OBJ
};

export default LearnToSwimRegistrationConstants;