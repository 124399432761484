import { Fragment } from 'react';

import useCartItems from './UseCartItems';

import PayByCheckPopupForm from '../../components/forms/PayByCheckPopupForm';
import DeleteCartItemPopup from '../../components/dialogs/DeleteCartItemPopup';
import ShoppingCartLoadingModal from '../../components/dialogs/ShoppingCartLoadingModal';
import CartItemsGrid from '../../components/grids/CartItemsGrid';
import FoundationDonation from '../../components/inputs/FoundationDonation';
import SwimmingWorldOptIn from '../../components/inputs/SwimmingWorldOptIn';
import TechFee from '../../components/inputs/TechFee';
import EmptyCart from '../../components/miniViews/EmptyCart';
import UnavailableCart from '../../components/miniViews/UnavailableCart';

import Headings from '../../../headings/Headings';

import SecondaryButton from '../../../buttons/SecondaryButton';
import PrimaryButton from '../../../buttons/PrimaryButton';
import ActionIntraPageButton from '../../../buttons/ActionIntraPageButton';

import { FormatMoneyWithSymbol } from '../../../../utils/FormatMoney';

import global from '../../../GlobalStyle.module.css';
import style from '../../Payments.module.css';

/**
 * Sub view that acts as the initial cart view. Displays cart items in grid and allows for checkout,
 * the deletion of items, toggle items to "pay later", & various optional payment fields.
 * @param {Function} onCancelClicked optional cancel button
 * @param {Boolean} showOnPayByCheck whether or not to allow pay by check
 */
const CartItems = ({ onCancelClicked, showOnPayByCheck }) => {
  const {
    isLoading,
    isProcessingOrder,
    state,
    displayPayByCheckModalState,
    deleteItemPopupState,
    onStateValueChanged,
    onCartItemSelectedChanged,
    onDeleteItem,
    onDeleteItemPopupConfirmed,
    onDeleteItemPopupCanceled,
    onCheckoutClicked,
    onPayByCheckClicked,
    onPayByCheckModalFormSubmitted,
    onPayByCheckModalCanceled
  } = useCartItems();

  if (!(state.cartItems?.length > 0) && isLoading !== true) {
    return <EmptyCart onCancelClicked={onCancelClicked} />;
  } else if (isProcessingOrder === true) {
    return <UnavailableCart onCancelClicked={onCancelClicked} />;
  } else {
    return (
      <Fragment>
        <CartItemsGrid
          cartItems={state.cartItems}
          subTotal={state.subTotal}
          onDeleteItem={onDeleteItem}
          onSelectedChanged={onCartItemSelectedChanged} />
        <div className="row">
          <div className="col-xs-12">
            <Headings.H4>Optional</Headings.H4>
          </div>
          <FoundationDonation foundationDonation={state.foundationDonation} onValueChanged={onStateValueChanged} />
          <TechFee technologyFee={state.technologyFee} coverTechnologyFee={state.coverTechnologyFee} onValueChanged={onStateValueChanged} />
          <SwimmingWorldOptIn swimmingWorldOptIn={state.swimmingWorldOptIn} onValueChanged={onStateValueChanged} />
        </div>
        <table className={global.UsasTable}>
          <tbody>
            <tr style={{ backgroundColor: 'transparent', border: 0 }}>
              <td className={style.ItemColumn}></td>
              <td className={style.SelectColumn}></td>
              <td className={style.ButtonColumn}></td>
              <td className={style.ButtonColumn} style={{ fontWeight: 'bold', textAlign: 'right' }}>Total:</td>
              <td className={[style.AmountColumn, 'text-right'].join(' ')} style={{ fontWeight: 'bold', paddingRight: 10 }}>{FormatMoneyWithSymbol(state.totalAmount)}</td>
            </tr>
          </tbody>
        </table>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 text-right">
            {onCancelClicked && (
              <Fragment><SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>&nbsp;</Fragment>
            )}
            <PrimaryButton type="button" onClick={onCheckoutClicked}>Check Out</PrimaryButton>
            {showOnPayByCheck === true && (
              <Fragment>&nbsp;<ActionIntraPageButton type="button" onClick={onPayByCheckClicked}>Pay By Check</ActionIntraPageButton></Fragment>
            )}
          </div>
        </div>
        {displayPayByCheckModalState === true && (
          <PayByCheckPopupForm
            onSubmitFormCallback={onPayByCheckModalFormSubmitted}
            onCloseModal={onPayByCheckModalCanceled} />
        )}
        {deleteItemPopupState.displayPopUp === true && (
          <DeleteCartItemPopup
            itemDescription={deleteItemPopupState.itemDescription}
            itemPrice={deleteItemPopupState.itemPrice}
            onConfirmDeleteItem={onDeleteItemPopupConfirmed}
            onCloseModal={onDeleteItemPopupCanceled} />
        )}
        <ShoppingCartLoadingModal
          isLoading={isLoading}
          onPaymentCancel={onCancelClicked} />
      </Fragment>
    );
  }
};

export default CartItems;