import { useEffect } from 'react';

import validate from './RegistrationPrimaryContactFormValidation';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  birthDate: Constants.BLANK_DATE_STRING,
  phoneNumber: '',
  email: '',
};

const useRegistrationPrimaryContactForm = (onSubmitFormCallback, providerObj) => {
  const { formState, errorState, isDirty, setIsDirty, setFormState, handleSubmit, updateFormState
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (providerObj) {
      const contact = providerObj.contacts[0];

      if (contact) {
        setFormState({
          ...formState,
          firstName: contact.firstName || '',
          lastName: contact.lastName || '',
          birthDate: contact.birthDate || Constants.BLANK_DATE_STRING,
          phoneNumber: contact.phoneNumber || '',
          email: contact.email || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerObj]);

  //TODO better way to do this?
  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useRegistrationPrimaryContactForm;