/* eslint-disable react/no-unescaped-entities */
import { Fragment } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';

import useStripeCheckoutForm from './UseStripeCheckoutForm';

import ShoppingCartLoadingModal from '../dialogs/ShoppingCartLoadingModal';

import LinkButton from '../../../buttons/LinkButton';
import PrimaryButton from '../../../buttons/PrimaryButton';
import SecondaryButton from '../../../buttons/SecondaryButton';
import PopUpModal from '../../../dialogs/PopUpModal';

import { FormatMoneyWithSymbol } from '../../../../utils/FormatMoney';
import Constants from '../../../../utils/Constants';

import style from '../../Payments.module.css';

const StripeCheckoutForm = ({ orderId, totalAmount, onTransactionComplete, onCancelClicked, resultPromise }) => {
  const {
    isLoading,
    errorMessages,
    handleSubmit,
    onCloseErrorPopup
  } = useStripeCheckoutForm(orderId, onTransactionComplete, resultPromise);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12">
            <LinkButton action={onCancelClicked}>Return to List of Items</LinkButton>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            Your purchase may take up to two minutes to complete. Please do not reload the page or navigate
            to another window while your purchase is being processed. If you have begun a payment process
            by clicking "Process Payment" and you lose connection or are unsure if the payment was successful
            for any reason, please reach out to <a href="mailto:support@usaswimming.org">support@usaswimming.org</a> to
            check if your payment has gone through before making any new payment attempts.
          </div>
        </div>
        <div className="row usas-extra-top-margin"></div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PaymentElement />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className='col-xs-12 text-right'>
            <SecondaryButton onClick={onCancelClicked}>Cancel</SecondaryButton>&nbsp;
            <PrimaryButton disabled={isLoading === true} type="submit">Pay {FormatMoneyWithSymbol(totalAmount)}</PrimaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={500}
        title={Constants.ERROR_MSG}
        displayPopUp={errorMessages.length > 0}>
        <div className={style.CenterPageContent}>
          {errorMessages.map((errorMessage, i) => <p key={i}>{errorMessage}</p>)}
          <br /><br />
          <PrimaryButton onClick={() => { onCloseErrorPopup(); }}>Ok</PrimaryButton>
        </div>
      </PopUpModal>
      <ShoppingCartLoadingModal isLoading={isLoading} loadingMessage={'Processing Payment...'} onPaymentCancel={onCancelClicked} />
    </Fragment>
  );
};

export default StripeCheckoutForm;