import { API_NAMES, initApi } from '../../../utils/HttpApiHelper';

import { createUrl } from '../PaymentsData';

const postCheckoutData = (cartItemDetailIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn, payByCheck, personId, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const url = `/Payments/Checkout`;
  const payload = {
    foundationDonation,
    coverTechnologyFee,
    swimmingWorldOptIn,
    payByCheck,
    personId,
    cartItemDetailIds
  };

  return api?.executeObject ? api.executeObject(url, 'POST', payload) : null;
};

const postPaymentCompleteData = (state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const url = `/Payments/Complete`;

  return api?.executeObject ? api.executeObject(url, 'POST') : null;
};

const getCartData = (orgUnitId, itemSubsystemId, itemContract, referenceId, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const url = createUrl('/Payments/RetrieveCart', orgUnitId, itemSubsystemId, itemContract, referenceId);

  return api?.executeObject ? api.executeObject(url, 'GET') : null;
};

const deleteCartItemData = (orgUnitId, itemSubsystemId, itemContract, referenceId, cartItemDetailId, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const url = createUrl(`/Payments/Cart/${cartItemDetailId}/Remove`, orgUnitId, itemSubsystemId, itemContract, referenceId);

  return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
};

const INITIAL_STATE = {
  currentStep: null,
  orgUnitId: null,
  itemSubsystemId: null,
  itemContract: null,
  referenceId: null
};

const PaymentsShoppingCartData = {
  INITIAL_STATE,
  postCheckoutData,
  postPaymentCompleteData,
  getCartData,
  deleteCartItemData
};

export default PaymentsShoppingCartData;