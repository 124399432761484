import {
  isValidAddressLine, isValid5DigitZip, isValid9DigitZip, isValidEmail, isValidDate, isValidNumber,
  isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber, validateAddress
} from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

const FUNDRAISING_GOAL_MAXIMUM = 19000000;

export const localValidate = async (formState) => {
  let errors = {};
  let addressError = false;


  const fundraisingGoal = parseFloat(formState.fundraisingGoal);

  if (formState.contactName.trim().length === 0) {
    errors.contactName = 'Contact Name is required';
  } else if (formState.contactName.trim().length > 200) {
    errors.contactName = 'Contact Name cannot exceed 200 characters'
  }

  if (formState.contactEmail.trim().length === 0) {
    errors.contactEmail = 'Contact Email is required';
  } else if (!isValidEmail(formState.contactEmail.trim())) {
    errors.contactEmail = 'Contact Email is not valid';
  } else if (formState.contactEmail.trim().length > 200) {
    errors.contactEmail = 'Contact Email cannot exceed 200 characters'
  }

  if (!formState.contactPhoneNumber) {
    errors.contactPhoneNumber = 'Phone Number is required';
  } else if (!isValidUSOrCanadianPhoneNumber(formState.contactPhoneNumber) && !isValidMexicanPhoneNumber(formState.contactPhoneNumber)) {
    errors.contactPhoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format';
  }

  if (formState.address1.trim().length === 0) {
    addressError = true;
    errors.address1 = 'Shipping Address 1 is required';
  } else if (!isValidAddressLine(formState.address1.trim())) {
    addressError = true;
    errors.address1 = 'Primary Mailing Club Address cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  if (formState.address2.trim() !== '') {
    if (!isValidAddressLine(formState.address2.trim())) {
      addressError = true;
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  if (formState.city.trim().length === 0) {
    addressError = true;
    errors.city = 'Shipping City is required';
  } else if (!isValidAddressLine(formState.city.trim())) {
    addressError = true;
    errors.city = 'Shipping City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  if (formState.stateCode.length === 0) {
    addressError = true;
    errors.stateCode = 'Shipping State is required';
  }

  if (formState.postalCode.trim().length === 0) {
    addressError = true;
    errors.postalCode = 'Zip Code is required';
  } else if (!isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  if (addressError === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1 !== formState.validatedAddress.address1
        || formState.address2 !== formState.validatedAddress.address2
        || formState.city !== formState.validatedAddress.city
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode !== formState.validatedAddress.postalCode
      )
    ) {
      if (formState.address1 !== formState.validatedAddress.address1) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2 !== formState.validatedAddress.address2) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city !== formState.validatedAddress.city) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode !== formState.validatedAddress.postalCode) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  if (formState.satDate === Constants.BLANK_DATE_STRING) {
    errors.satDate = 'Swim-a-Thon Date is required';
  } else if (!isValidDate(formState.satDate)) {
    errors.satDate = 'Swim-a-Thon Date must be a valid date';
  }

  if (formState.signature.trim().length === 0) {
    errors.signature = 'Signature is required';
  } else if (formState.signature.trim().length > 200) {
    errors.signature = 'Signature cannot exceed 200 characters'
  }

  if (formState.signatureDate === Constants.BLANK_DATE_STRING) {
    errors.signatureDate = 'Signature Date is required';
  } else if (!isValidDate(formState.signatureDate)) {
    errors.signatureDate = 'Signature Date must be a valid date';
  }

  if (isNaN(fundraisingGoal)) {
    errors.fundraisingGoal = 'Fundraising Goal must be a valid number';
  } else if (fundraisingGoal < 1) {
    errors.fundraisingGoal = 'Fundraising Goal must be a valid amount';
  } else if (fundraisingGoal > FUNDRAISING_GOAL_MAXIMUM) {
    errors.fundraisingGoal = 'Fundraising Goal cannot be greater than $19,000,000.00';
  }

  if (!isValidNumber(formState.numberOfAthletes)) {
    errors.numberOfAthletes = 'Number of Athletes must be a valid number';
  } else if (formState.numberOfAthletes < 1) {
    errors.numberOfAthletes = 'Number of Athletes must be a valid amount';
  }
  
  return errors;
};

const SATContractFormValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};

export default SATContractFormValidation;