import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../wrappers/ReactRouterDom';

import validate from './FacilityValidation';

import useCountryCombobox from '../../../comboboxes/countryCombobox/UseCountryCombobox';

import useFacilityData from '../../../../state/facility/UseFacilityData';
import useClubAffiliationTypeData from '../../../../state/clubAffiliationType/UseClubAffiliationTypeData';
import useSecurityData from '../../../../state/security/UseSecurityData';

import useForm from '../../../../utils/UseForm';
import Constants from '../../../../utils/Constants';

const TAXONOMIES = ['FacilityDetail'];
const SCOPE = 'Facility';
const US_COUNTRY_CODE = 'US';

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useAddFacility = (saveUrl, cancelUrl, orgUnitId) => {
  const navigate = useNavigate();
  const { facilityState, getPotentialFacilityDupes, postFacility, postFacilityClubLink, clearObjData } = useFacilityData();
  const { clubAffiliationTypeState } = useClubAffiliationTypeData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const { getCountryNameById } = useCountryCombobox();
  const { formState, errorState, onValueTextPairChanged, handleSubmit, updateFormState, setIsDirty, setFormData } = useForm(getInitialFormState, submitFormCallback, validate);
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const [state, setState] = useState({
    submitClicked: false, tryLink: false, tryGetContextSecurity: false,
    tryRedirect: false, facilityId: Constants.DEFAULT_ID
  });
  const [dupsState, setDupsState] = useState(getInitialDupsState);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  const onAddDupFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setDupsState(getInitialDupsState());
    postFacility(createPostFacilityObject(formState));
    if (orgUnitId) {
      setState({ ...state, tryLink: true });
    }
    else {
      setState({ ...state, tryGetContextSecurity: true });
    }
  };

  const onCancelDupFacilityClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      submitClicked: false
    });
    setDupsState(getInitialDupsState());
    setIsDirty(true);
  };

  const onCancelClicked = () => {
    if (facilityState.isSaving === false && dupsState.isArrayLoading === false) {
      navigate(cancelUrl);
    }
  }

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formState.isInternational === false) {
      setFormData({
        ...formState,
        country: US_COUNTRY_CODE,
        countryName: getCountryNameById(US_COUNTRY_CODE)
      }, true);
    } else {
      setFormData({
        ...formState,
        country: '',
        countryName: ''
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isInternational]);

  useEffect(() => {
    if (dupsState.isArrayLoading === false && dupsState.isArrayLoaded === true
      && dupsState.arrayData.length === 0 && state.submitClicked === true) {
      postFacility(createPostFacilityObject(formState));
      if (orgUnitId) {
        setState({ ...state, tryLink: true });
      }
      else {
        setState({ ...state, tryGetContextSecurity: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dupsState]);

  useEffect(() => {
    if (state.submitClicked === true && state.tryRedirect === false
      && facilityState.isSaved === true && facilityState.isSaving === false) {
      if (state.tryLink === true) {
        postFacilityClubLink(createAddFacilityClubLinkObject(facilityState.objData.facilityId, orgUnitId));
        setState({ ...state, tryRedirect: true, facilityId: facilityState.objData.facilityId });
      }
      else if (state.tryGetContextSecurity === true) {
        getContextSecurity(facilityState.objData.facilityId, TAXONOMIES, SCOPE);
        setState({ ...state, tryRedirect: true, facilityId: facilityState.objData.facilityId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, facilityState]);

  useEffect(() => {
    if (state.submitClicked === true && state.tryRedirect === true && contextSecurityState.isObjLoading === false &&
      state.facilityId > 0 && facilityState.isSaved === true && facilityState.isSaving === false) {
      navigate(saveUrl, { state: { facilityId: state.facilityId, isNewFacility: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, facilityState, contextSecurityState]);

  function submitFormCallback(formState) {
    getPotentialFacilityDupes(formState.address1, formState.city, formState.stateCode, formState.postalCode,
      dupsState, setDupsState);

    setState({
      ...state,
      submitClicked: true
    });
  };

  function createPostFacilityObject(formState) {
    return {
      facilityName: formState.facilityName,
      address1: formState.address1,
      address2: formState.address2,
      city: formState.city,
      stateCode: formState.stateCode,
      postalCode: formState.postalCode,
      contactName: formState.contactName,
      contactEmailAddress: formState.contactEmailAddress,
      contactPhoneNumber: formState.contactPhoneNumber,
      hostMeet: formState.hostMeet,
      clubAffiliationId: formState.clubAffiliationId,
      altitude: formState.altitude.toString().replace(',', ''),
      isInternational: formState.isInternational,
      country: formState.country,
      depthRequirementMet: formState.depthRequirementMet
    };
  };

  function createAddFacilityClubLinkObject(facilityId, orgUnitId) {
    return {
      facilityId: facilityId,
      orgUnitId: orgUnitId
    }
  }

  return {
    formState,
    errorState,
    dupsState,
    clubAffiliationTypeState,
    facilityState,
    onFormValueChanged: updateFormState,
    onCancelClicked,
    onValueTextPairChanged,
    onAddDupFacilityClicked,
    onCancelDupFacilityClicked,
    handleSubmitProxy,
    setFormData,
    validateState,
    contextSecurityState
  };
};

export default useAddFacility;

function getInitialFormState() {
  return {
    facilityName: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    country: '',
    countryName: '',
    stateName: '',
    postalCode: '',
    contactName: '',
    contactEmailAddress: '',
    contactPhoneNumber: '',
    hostMeet: '',
    clubAffiliationId: Constants.DEFAULT_POSITIVE_ID,
    altitude: Constants.DEFAULT_ALTITUDE,
    isInternational: false,
    validatedAddress: null,
    showedAddressSuggestion: false,
    depthRequirementMet: false
  };
};

function getInitialDupsState() {
  return {
    arrayData: [],
    isArrayLoaded: false,
    isArrayLoading: false
  };
};