import { navLinks } from '../UseMemberAdministration';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useOrgUnitSelection = () => {
    const { LSC_MEMBER_ADMIN_ROLE_GROUP_ID } = useEnvironmentVariableData();
    return {
        navLinks,
        LSC_MEMBER_ADMIN_ROLE_GROUP_ID
    };
};

export default useOrgUnitSelection;