import { Fragment } from "react";

import useRegistrationSubmitForm from "./UseRegistrationSubmitForm";

import { getUploadedFileName } from "../../../learnToSwim/utils/LearnToSwimUtils";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const RegistrationSubmitForm = ({ submitButtonText = 'Submit', secondaryButtonText = 'Back',
  providerObj, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    isSaving,
    formState,
    errorState,
    handleSubmit,
    onFileUnload,
    onFileLoad
  } = useRegistrationSubmitForm(onSubmitFormCallback, providerObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12">
            {formState.logoFile?.size > 0 || formState.logoUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {/*formState.logoUrl !== '' && <PrimaryButton type="button" onClick={(e) => onFileDownload(e)}>Download Uploaded File</PrimaryButton>*/}
                  <p><b>File:</b> {formState.logoFile.name || getUploadedFileName(formState.logoUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="logo-file-upload" className={global.FileUpload}>Upload Logo</label>
                  <input hidden id="logo-file-upload" type="file" onChange={(e) => onFileLoad(e)} />&nbsp;(*.jpg)
                  {errorState.logoFile && <p className={global.ErrorMessage}>{errorState.logoFile}</p>}
                </Fragment>
              )
            }
          </div>
        </div>
        <div className="row pull-right">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default RegistrationSubmitForm;