import { useEffect, useState } from "react";
import { formatDate } from "../../../../../common/utils/DateFunctions";

const useLearnToSwimRegistrationStaffDupPopup = (dupArrayData, newPersonObj, onPersonConfirmed) => {
  const [dupGridState, setDupGridState] = useState([]);
  const [newGridState, setNewGridState] = useState([]);

  const onContinueClicked = () => {
    let matchFound = false;

    for (const dup of dupGridState) {
      if (dup.isSelected === true) {
        matchFound = true;
        onPersonConfirmed(dup);
        break;
      }
    }

    if (matchFound === false) {
      onPersonConfirmed(newPersonObj);
    }
  };

  const onSelectGridPerson = (personId) => {
    let newDupGridState = [];

    for (const dup of dupGridState) {
      newDupGridState.push({
        ...dup,
        isSelected: personId === dup.personId ? true : false
      });
    }

    setDupGridState(newDupGridState);
    setNewGridState([{
      ...newGridState[0],
      isSelected: personId === '' ? true : false
    }]);
  };

  useEffect(() => {
    if (Array.isArray(dupArrayData) && newPersonObj) {
      if (dupArrayData.length > 0) {
        setDupGridState(dupArrayData.map(x => {
          let orgDuration = { orgUnitName: '', effectiveDate: '', expirationDate: '' };

          if (Array.isArray(x.orgDurations) && x.orgDurations.length > 0) {
            orgDuration = {
              orgUnitName: x.orgDurations[0].orgUnitName,
              effectiveDate: x.orgDurations[0].effectiveDate,
              expirationDate: x.orgDurations[0].expirationDate
            };
          }

          return {
            personId: x.personId,
            firstName: x.firstName,
            lastName: x.lastName,
            email: x.email,
            birthDate: formatDate(x.birthDate),
            phoneNumber: newPersonObj.phoneNumber,
            orgDuration,
            isSelected: false
          };
        }));
        setNewGridState([{
          personId: '',
          firstName: newPersonObj.firstName,
          lastName: newPersonObj.lastName,
          email: newPersonObj.email,
          birthDate: newPersonObj.birthDate,
          phoneNumber: newPersonObj.phoneNumber,
          orgDurations: { organization: '', effectiveDate: '', expirationDate: '' },
          isSelected: true
        }]);
      } else {
        onPersonConfirmed(newPersonObj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dupGridState,
    newGridState,
    onContinueClicked,
    onSelectGridPerson
  };
};

export default useLearnToSwimRegistrationStaffDupPopup;