import { isValidAddressLine, isValid5DigitZip, isValid9DigitZip, validateAddress } from '../../../../common/utils/validation';
import { isValidLink } from '../../preComp/utils/PreCompUtils';
import Constants from '../../../../common/utils/Constants';

export const localValidate = async (formState) => {
  let errors = {};

  const today = new Date();
  const currentYear = today.getFullYear();

  let addressError = false;

  //Primary Mailing Club Address
  if (formState.address1.trim().length === 0) {
    addressError = true;
    errors.address1 = 'Primary Mailing Address Line 1 is required';
  }
  else if (!isValidAddressLine(formState.address1.trim())) {
    addressError = true;
    errors.address1 = 'Primary Mailing Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //Address Line 2
  if (formState.address2.trim() !== '') {
    if (!isValidAddressLine(formState.address2.trim())) {
      addressError = true;
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  //City
  if (formState.city.trim().length === 0) {
    addressError = true;
    errors.city = 'City is required';
  }
  else if (!isValidAddressLine(formState.city.trim())) {
    addressError = true;
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //State
  if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode.trim() === '') {
    addressError = true;
    errors.stateCode = 'State is required and must be valid';
  }

  //Zip
  if (formState.postalCode.trim().length === 0) {
    addressError = true;
    errors.postalCode = 'Zip Code is required';
  } else if (!isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  if (addressError === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1 !== formState.validatedAddress.address1
        || formState.address2 !== formState.validatedAddress.address2
        || formState.city !== formState.validatedAddress.city
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode !== formState.validatedAddress.postalCode
      )
    ) {
      if (formState.address1 !== formState.validatedAddress.address1) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2 !== formState.validatedAddress.address2) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city !== formState.validatedAddress.city) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode !== formState.validatedAddress.postalCode) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  //Year Founded
  if (formState.yearFounded && formState.yearFounded.length > 0) {
    if (!Number.isInteger(parseInt(formState.yearFounded))) {
      errors.yearFounded = 'Not a valid value for Year Founded';
    } else if (parseInt(formState.yearFounded) > currentYear) {
      errors.yearFounded = 'Year Founded cannot be in the future';
    }
    else if (parseInt(formState.yearFounded) < 1900 || parseInt(formState.yearFounded) > 2100) {
      errors.yearFounded = 'Not a valid value for Year Founded';
    }
  }

  //Website Address
  if (formState.websiteAddress.trim() !== '') {
    if (!isValidLink(formState.websiteAddress)) {
      errors.websiteAddress = `Website Address must begin with 'http://' or 'https://'`;
    }
    else if (formState.websiteAddress.trim().length > 200) {
      errors.websiteAddress = 'Website Address cannot exceed 200 characters';
    }
  }

  //Is Club Active?
  if (formState.isActive === false) {
    if (formState.clubInactiveReasonId === Constants.DEFAULT_ID) {
      errors.clubInactiveReasonId = 'Club Inactive Reason is required';
    }
  }

  //Club Owner Type
  if (formState.ownerTypeId === Constants.DEFAULT_ID) {
    errors.ownerTypeId = 'Owner Type is required';
  }

  //Tax Listing
  if (formState.businessEntityTypeId === Constants.DEFAULT_ID) {
    errors.businessEntityTypeId = 'Taxing Listing is required';
  }

  return errors;
};

const ClubInfoValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};

export default ClubInfoValidation;