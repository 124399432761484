import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.logoFile?.size) {
    if (formState.logoFile.type !== Constants.FILE_TYPE_JPG) {
      errors.logoFile = 'File type not permitted. Please provide a (*.jpg) file.';
    }
  }

  return errors;
};

const RegistrationSubmitFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default RegistrationSubmitFormValidation;