import Constants from "../../../../common/utils/Constants";

const isValidLink = (link) => {
  try {
    const trimmedLink = link.trim();
    const http = trimmedLink.substring(0, 7);

    if (http === 'http://' && link.length > 7) {
      return true;
    } else {
      const https = trimmedLink.substring(0, 8);

      if (https === 'https://' && link.length > 8) {
        return true;
      } else {
        return false;
      }
    }
  } catch {
    return false;
  }
};

const createProviderObjFromEntryForm = (formState, providerObj) => {
  return {
    ...providerObj,
    entityName: formState.entityName,
    tradeName: formState.tradeName,
    taxId: formState.taxId
  };
};

const createProviderObjFromProviderInfoForm = (formState, providerObj) => {
  return {
    ...providerObj,
    learnToSwimCurriculumId: formState.learnToSwimCurriculumId,
    website: formState.website,
    offersFinancialAssistance: formState.offersFinancialAssistance,
    currentLTSGrantRecipient: formState.currentLTSGrantRecipient,
    affiliatedWithUsasClub: formState.affiliatedWithUsasClub,
    usasClubId: formState.affiliatedWithUsasClub === true ? formState.usasClubId : ''
  };
};

const createProviderObjFromPrimaryContactForm = (formState, providerObj) => {
  return {
    ...providerObj,
    contacts: [
      {
        personId: formState.personId || '',
        firstName: formState.firstName || '',
        lastName: formState.lastName || '',
        birthDate: formState.birthDate || Constants.BLANK_DATE_STRING,
        email: formState.email || '',
        phoneNumber: formState.phoneNumber || ''
      }
    ]
  };
};

const createProviderObjFromFacilityContactAddForm = (formState, providerObj, orgUnitFacilityObj, targetIndex) => {
    const newFacilities = JSON.parse(JSON.stringify(providerObj.facilities));
    const newOrgUnitFacilityObj = {
      ...orgUnitFacilityObj,
      clubFacilityContactName: formState.clubFacilityContactName,
      clubFacilityContactEmailAddress: formState.clubFacilityContactEmailAddress,
      clubFacilityContactPhoneNumber: formState.clubFacilityContactPhoneNumber,
      facility: {
        ...orgUnitFacilityObj.facility,
        contactName: formState.contactName,
        contactEmailAddress: formState.contactEmailAddress,
        contactPhoneNumber: formState.contactPhoneNumber
      }
    }

  if (targetIndex >= 0) {
    newFacilities[targetIndex] = newOrgUnitFacilityObj;
  } else {
    newFacilities.push(newOrgUnitFacilityObj);
  }

  return {
    ...providerObj,
    facilities: newFacilities
  };
};

export {
  isValidLink, createProviderObjFromEntryForm, createProviderObjFromProviderInfoForm,
  createProviderObjFromPrimaryContactForm, createProviderObjFromFacilityContactAddForm
};