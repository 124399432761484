import { useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { FACILITIES_NAME } from "../components/leftNav/LearnToSwimRegistrationLeftNavConstants";

import NavLinks from "../utils/NavLinks";

import useLearnToSwimRegistrationData from "../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData";
import useLearnToSwimRegistrationLeftNavData from "../../../state/learnToSwimRegistrationLeftNav/UseLearnToSwimRegistrationLeftNavData";

const INITIAL_STATE = {
  displayError: false
};

const useLearnToSwimRegistrationFacilities = () => {
  const navigate = useNavigate();
  const { ltsProviderRegistrationState, putLTSProviderRegistration } = useLearnToSwimRegistrationData();
  const { updateCurrentLeftNavData } = useLearnToSwimRegistrationLeftNavData();
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = () => {
    const facilities = ltsProviderRegistrationState.objData?.facilities;

    if (facilities.length > 0) {
      navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_SUBMIT);
    } else {
      setState({
        ...state,
        displayError: true
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_PRIMARY_CONTACT);
  };

  const onAddFacilityClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES_SEARCH);
  };

  const onEditFacilityClicked = (facilityId) => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES_DETAIL, { state: { facilityId } });
  };

  const onDeleteFacilityClicked = (facilityId) => {
    const facilities = JSON.parse(JSON.stringify(ltsProviderRegistrationState.objData?.facilities));
    const targetIndex = facilities.findIndex(x => x.facilityId === facilityId);

    if (targetIndex >= 0) {
      facilities.splice(targetIndex, 1);

      const providerObj = {
        ...ltsProviderRegistrationState.objData,
        facilities
      };

      const putLTSProviderRegistrationPromise = putLTSProviderRegistration(providerObj);

      if (putLTSProviderRegistrationPromise !== null) {
        putLTSProviderRegistrationPromise.then((newState) => {
          if (newState !== null) {
            setState({
              ...state,
              displayError: false
            });

            if (newState.objData?.facilities?.length > 0) {
              updateCurrentLeftNavData(FACILITIES_NAME, true);
            } else {
              updateCurrentLeftNavData(FACILITIES_NAME, false);
            }
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
  };

  return {
    isLoading: ltsProviderRegistrationState.isObjLoading,
    state,
    ltsProviderRegistrationState,
    onContinueClicked,
    onBackClicked,
    onAddFacilityClicked,
    onEditFacilityClicked,
    onDeleteFacilityClicked
  };
};

export default useLearnToSwimRegistrationFacilities;