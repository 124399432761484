import React, { Fragment } from 'react';
import global from '../../../../common/components/GlobalStyle.module.css';

import useClubRenewalRenew from './UseClubRenewalRenew';

import Headings from '../../../../common/components/headings/Headings';
import OfferingTypeRegistrationRadiobuttonList from '../../../components/radiobuttonlists/OfferingTypeRegistrationRadiobuttonList';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';
import Input from '../../../../common/components/inputs/Input';

const ClubRenewalRenew = () => {
  const {
    baseUsasClubState,
    formState,
    errorState,
    clubRenewState,
    clubRegistrationStatusState,
    onFormValueChanged,
    handleSubmit,
    onBackClicked
  } = useClubRenewalRenew();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Renewal</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p className={global.HeaderText}><strong>LSC:</strong>&nbsp;&nbsp;{baseUsasClubState.objData.orgUnit?.parentOrgUnitCode || ''}</p>
          <p className={global.HeaderText}><strong>Club Code:</strong>&nbsp;&nbsp;{baseUsasClubState.objData.orgUnit?.orgUnitCode || ''}</p>
          <p className={global.HeaderText}><strong>Club Name:</strong>&nbsp;&nbsp;{baseUsasClubState.objData.orgUnit?.orgUnitName || ''}</p>
          {clubRegistrationStatusState.objData.canBeRenewed === false && 
          <p className={global.HeaderText}><strong>Status:</strong>&nbsp;&nbsp;{clubRegistrationStatusState.objData.status}</p>
          }
        </div>
      </div>
      {clubRegistrationStatusState.isObjLoaded === true && 
        clubRegistrationStatusState.objData.canBeRenewed === true &&  
        clubRegistrationStatusState.objData.status !== 'Pending Payment' ?
        <Fragment>
          <form onSubmit={handleSubmit} noValidate>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <OfferingTypeRegistrationRadiobuttonList
                  label={"Select a Club Type"}
                  name={"offeringInstanceId"}
                  error={errorState.offeringInstanceId}
                  message={errorState.offeringInstanceId}
                  value={formState.offeringInstanceId}
                  isVertical={true}
                  onChange={(value) => { onFormValueChanged('offeringInstanceId', parseInt(value)) }}
                  orgUnitId={formState.lscId}
                  category={'club'}
                />
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12 col-sm-12 col-md-6 usas-extra-bottom-margin">
                <Input
                  label="Registration Signature"
                  name="registrationSignature"
                  value={formState.registrationSignature}
                  error={errorState.registrationSignature}
                  message={errorState.registrationSignature}
                  onChange={(value) => { onFormValueChanged('registrationSignature', value); }} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                <PrimaryButton type="submit">Submit Payment</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
              </div>
            </div>
          </form>
        </Fragment> : <Fragment><strong>Cannot renew at this time</strong></Fragment>
      }
      <PopUpModal 
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={clubRegistrationStatusState.isObjLoading || baseUsasClubState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={clubRenewState.isSaving} />
    </Fragment>
  );
};

export default ClubRenewalRenew;