import { useEffect } from 'react';

import validate from './RegistrationProviderInfoFormValidation';

import useLearnToSwimClubsData from '../../../../state/learnToSwimClubs/UseLearnToSwimClubsData';
import useLearnToSwimCurriculumData from '../../../../state/learnToSwimCurriculum/UseLearnToSwimCurriculumData';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  entityName: '',
  tradeName: '',
  taxId: '',
  learnToSwimCurriculumId: Constants.DEFAULT_ID,
  learnToSwimCurriculumName: '--',
  website: '',
  offersFinancialAssistance: '',
  currentLTSGrantRecipient: '',
  affiliatedWithUsasClub: '',
  usasClubId: '',
  usasClubName: ''
};

const useRegistrationProviderInfoForm = (onSubmitFormCallback, providerObj) => {
  const { ltsClubsState } = useLearnToSwimClubsData();
  const { ltsCurriculumState } = useLearnToSwimCurriculumData();
  const { formState, errorState, isDirty, setIsDirty, setFormState, handleSubmit, updateFormState, onValueTextPairChanged
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (providerObj && ltsClubsState.areItemsLoaded === true && ltsCurriculumState.areItemsLoaded === true) {
      let usasClub = null;
      let learnToSwimCurriculum = null;

      if (providerObj.affiliatedWithUsasClub === true && providerObj.usasClubId) {
        usasClub = ltsClubsState.items.find(x => x.id === providerObj.usasClubId);
      }

      if (providerObj.learnToSwimCurriculumId > 0) {
        learnToSwimCurriculum = ltsCurriculumState.items.find(x => x.id === providerObj.learnToSwimCurriculumId);
      }

      setFormState({
        ...formState,
        entityName: providerObj.entityName || '',
        tradeName: providerObj.tradeName || '',
        taxId: providerObj.taxId || '',
        learnToSwimCurriculumId: learnToSwimCurriculum?.id || Constants.DEFAULT_ID,
        learnToSwimCurriculumName: learnToSwimCurriculum?.name || '--',
        website: providerObj.website || '',
        offersFinancialAssistance: providerObj.offersFinancialAssistance === true || providerObj.offersFinancialAssistance === false
          ? providerObj.offersFinancialAssistance
          : '',
        currentLTSGrantRecipient: providerObj.currentLTSGrantRecipient === true || providerObj.currentLTSGrantRecipient === false
          ? providerObj.currentLTSGrantRecipient
          : '',
        affiliatedWithUsasClub: providerObj.affiliatedWithUsasClub === true || providerObj.affiliatedWithUsasClub === false
          ? providerObj.affiliatedWithUsasClub
          : '',
        usasClubId: usasClub?.id || '',
        usasClubName: usasClub?.name || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerObj, ltsClubsState.areItemsLoaded, ltsCurriculumState.areItemsLoaded]);

  //TODO better way to do this?
  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    isLoading: ltsClubsState.isArrayLoading || ltsCurriculumState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useRegistrationProviderInfoForm;