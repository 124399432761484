const NavLinks = {
  LEARN_TO_SWIM_REGISTRATION_ENTRY: '/learntoswimregistration/entry',
  LEARN_TO_SWIM_REGISTRATION_PROVIDER_INFO: '/learntoswimregistration/providerinfo',
  LEARN_TO_SWIM_REGISTRATION_PRIMARY_CONTACT: '/learntoswimregistration/primarycontact',
  LEARN_TO_SWIM_REGISTRATION_FACILITIES: '/learntoswimregistration/facilities',
  LEARN_TO_SWIM_REGISTRATION_FACILITIES_SEARCH: '/learntoswimregistration/facilities/search',
  LEARN_TO_SWIM_REGISTRATION_FACILITIES_ADD: '/learntoswimregistration/facilities/add',
  LEARN_TO_SWIM_REGISTRATION_FACILITIES_DETAIL: '/learntoswimregistration/facilities/detail',
  LEARN_TO_SWIM_REGISTRATION_SUBMIT: '/learntoswimregistration/submit'
};

export default NavLinks;