import { useEffect } from 'react';

import validate from './RegistrationSubmitFormValidation';

import useLearnToSwimLogoData from '../../../../state/learnToSwimLogo/UseLearnToSwimLogoData';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  logoUrl: '',
  logoFile: {}
};

const useRegistrationSubmitForm = (onSubmitFormCallback, providerObj) => {
  const { ltsLogoState, postLTSLogo } = useLearnToSwimLogoData();
  const { formState, errorState, isDirty, setIsDirty, setFormState, handleSubmit, updateFormState,
    setErrors
  } = UseForm(INITIAL_FORM_STATE, localSubmitFormCallback, validate);

  const onFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, logoFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_JPG) {
        updateFormState('logoFile', selectedFile);
      } else {
        setErrors({ ...errorState, logoFile: 'File type not permitted. Please provide a (*.jpg) file.' });
      }
    }
  };

  const onFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      logoFile: {},
      logoUrl: ''
    });
  };

  //TODO better way to do this?
  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  function localSubmitFormCallback(formState) {
    if (formState.logoFile?.size) {
      const postLTSLogoPromise = postLTSLogo(formState.logoFile);

      if (postLTSLogoPromise !== null) {
        postLTSLogoPromise.then((newState) => {
          if (newState !== null) {
            onSubmitFormCallback({ logoUrl: newState.objData?.uploadUrl });
          }
        }).catch((e) => {
          //TODO state error
        });
      }
    } else {
      onSubmitFormCallback({ logoUrl: null });
    }
  };

  return {
    isSaving: ltsLogoState.isObjLoading,
    formState,
    errorState,
    handleSubmit,
    onFileLoad,
    onFileUnload
  };
};

export default useRegistrationSubmitForm;