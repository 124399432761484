import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useSwimAThonData from '../../state/swimAThon/UseSwimAThonData';

const BACK_TO_LABELS = {
  searchBackToLabel: 'Back to Swim-a-Thon Search',
  selectionBackToLabel: 'Back to Selection'
};

const useClubSwimAThonContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { satState, satFiltersState, getSAT, clearObjData } = useSwimAThonData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_SWIMATHON_CONTRACTS);
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (satFiltersState.backToSystem === 'SATSearch') {
      goToSATSearch();
    } else if (satFiltersState.backToSystem === 'SATSelection') {
      goToSATSelection();
    }
  };

  useEffect(() => {
    if (location.state?.performGet === true && satState.orgUnitId) {
      getSAT(satState.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  function goToSATSearch() {
    clearObjData();
    navigate(NavLinks.CLUB_SWIMATHON_SEARCH);
  };

  function goToSATSelection() {
    clearObjData();
    navigate(NavLinks.CLUB_SWIMATHON_SELECTION);
  };

  function getBackToLabel() {
    if (satFiltersState.backToSystem === 'SATSearch') {
      return BACK_TO_LABELS.searchBackToLabel;
    } else if (satFiltersState.backToSystem === 'SATSelection') {
      return BACK_TO_LABELS.selectionBackToLabel;
    } else {
      return 'Back To Swim-a-Thon';
    }
  };

  return {
    satState,
    backToLabel: getBackToLabel(),
    onHomeClicked,
    onBackClicked
  };
};

export default useClubSwimAThonContextView;