import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useForm from '../../../../common/utils/UseForm';

import validate from './ClubRenewalRenewValidation';
import Constants from '../../../../common/utils/Constants';
import ClubRegistrationConstants from '../../clubRegistration/utils/ClubRegistrationConstants';

import useClubRenewData from '../../../state/clubRenew/UseClubRenewData';
import useClubRegistrationStatusData from '../../../state/clubRegistration/clubRegistrationStatus/UseClubRegistrationStatusData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import NavLinks from '../NavLinks';

const useClubRecognitionProgramLevel = () => {
  const navigate = useNavigate();
  const { handleSubmit, updateFormState, formState, errorState, setFormData } = useForm(getFormInitialState(), submitFormCallback, validate);
  const { clubRenewState, postClubRenew } = useClubRenewData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { clubRegistrationStatusState, getClubRegistrationStatus } = useClubRegistrationStatusData();


  function submitFormCallback() {
    postClubRenew(
      baseUsasClubState.objData.usasClubId,
      formState.offeringInstanceId,
      formState.registrationSignature);
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.CLUB_RENEWAL_SELECTION);
  }

  useEffect(() => {
    if (clubRenewState.isObjLoaded === true && clubRenewState.objData.status === ClubRegistrationConstants.PENDING_PAYMENT_STATUS) {
      const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
      if (paymentsRoute && paymentsRoute.route) {
        navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRenewState]);

  useEffect(() => {
    if (Object.keys(baseUsasClubState.objData).length > 0 && formState.lscId === Constants.DEFAULT_ID) {
      setFormData({
        ...formState,
        lscId: baseUsasClubState.objData.orgUnit?.parentOrgUnitId
      });
    }

    if (baseUsasClubState.isObjLoading === false && baseUsasClubState.isObjLoaded === true) {
      getClubRegistrationStatus(baseUsasClubState.objData.usasClubId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState.objData, formState.lscId])

  return {
    baseUsasClubState,
    formState,
    errorState,
    clubRenewState,
    clubRegistrationStatusState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onBackClicked
  };

  function getFormInitialState() {
    return {
      offeringInstanceId: '',
      offeringInstance: {},
      selectedRegistration: {},
      lscId: Constants.DEFAULT_ID,
      usasFeeAmount: '',
      lscFeeAmount: '',
      offeringName: '',
      offeringDescription: '',
      registrationSignature: ''
    }
  };
}

export default useClubRecognitionProgramLevel;