import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './utils/NavLinks';

import useLearnToSwimData from '../../state/learnToSwim/UseLearnToSwimData';

import useSecurityData from '../../../common/state/security/UseSecurityData';

const BACK_TO_LABELS = {
  learnToSwimSearch: 'Back to Learn To Swim Provider Search',
  learnToSwimMyProviders: 'Back to My Learn To Swim Providers'
};

const INITIAL_BACK_TO_STATE = {
  backToLabel: 'Back To Learn To Swim Root',
  backToRoute: '/'
};

const useLearnToSwimContextView = () => {
  const navigate = useNavigate();
  const { securityState } = useSecurityData();
  const { ltsProviderState, resetLTSProviderState } = useLearnToSwimData();
  const [backToState, setBackToState] = useState(INITIAL_BACK_TO_STATE);

  const onHomeClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_PROVIDER_INFO);
  };

  const onBackClicked = () => {
    resetLTSProviderState();

    navigate(backToState.backToRoute);
  };

  useEffect(() => {
    if (Array.isArray(securityState.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'LEARN_TO_SWIM_SEARCH')) {
        setBackToState({
          ...backToState,
          backToLabel: BACK_TO_LABELS.learnToSwimSearch,
          backToRoute: appRoutes.find(x => x.routeName === 'LEARN_TO_SWIM_SEARCH')?.route
        });
      } else if (appRoutes.some(x => x.routeName === 'LEARN_TO_SWIM_MY_PROVIDERS')) {
        setBackToState({
          ...backToState,
          backToLabel: BACK_TO_LABELS.learnToSwimMyProviders,
          backToRoute: appRoutes.find(x => x.routeName === 'LEARN_TO_SWIM_MY_PROVIDERS')?.route
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState.userInfo]);

  return {
    ltsProviderState,
    backToLabel: backToState.backToLabel,
    onBackClicked,
    onHomeClicked
  };
};

export default useLearnToSwimContextView;