
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import ClubContextRoutes from './ClubContextRoutes';

import ClubContextProviders from './ClubContextProviders';

import ClubSearch from './clubSearch/ClubSearch';
import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import ClubReporting from './clubReporting/ClubReporting';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const ClubRoutes = () => {
  const { navRoutes } = useNavRoutes();

  return (
    <Routes>
      <Route path={navRoutes.CLUB_SEARCH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_SEARCH}
            editElement={<ClubSearch />} />
        } />
      <Route path={navRoutes.CLUB_MY_CLUB?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_MY_CLUB}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={navRoutes.CLUB_REPORTS?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.CLUB_REPORTS}
            editElement={<ClubReporting />} />
        } />

      <Route path={'/*'} element={<ClubContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.CLUB_SEARCH?.path} replace />} />
    </Routes>
  );
};

const ClubBasicRoutes = () => {
  return (
    <ClubContextProviders>
      <ClubRoutes />
    </ClubContextProviders>
  );
};

export default ClubBasicRoutes;