import { Fragment } from 'react';

import usePaymentComplete from './UsePaymentComplete';

import ShoppingCartLoadingModal from '../../components/dialogs/ShoppingCartLoadingModal';

import PrimaryButton from '../../../buttons/PrimaryButton';
import SecondaryButton from '../../../buttons/SecondaryButton';

/**
 * Sub view that is displayed after a successful payment transaction.
 * @param {Function} onReturnClicked optional return button
 */
const PaymentComplete = ({ onReturnClicked }) => {
  const {
    isLoading,
    hasReceipt,
    isPending,
    onBackToCartClicked,
    onDownloadReceiptClicked
  } = usePaymentComplete();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          {hasReceipt === true
            ? <p>Your payment was successful.</p>
            : isPending === true
              ? <p>Your payment was successful. You will be able to view your receipt from your dashboard once your payment has finished processing.</p>
              : <p>Your payment was successful. You can view your receipt from your dashboard.</p>
          }
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          {hasReceipt === true &&
            <Fragment><PrimaryButton type="button" onClick={() => onDownloadReceiptClicked()}>Download Receipt</PrimaryButton>&nbsp;</Fragment>
          }
          {onReturnClicked
            ? <SecondaryButton type="button" onClick={onReturnClicked}>Return</SecondaryButton>
            : <SecondaryButton type="button" onClick={() => onBackToCartClicked()}>Back To Cart</SecondaryButton>
          }
        </div>
      </div>
      <ShoppingCartLoadingModal isLoading={isLoading} />
    </Fragment>
  );
};

export default PaymentComplete;