import { useEffect } from 'react';
import { CART_STEP_CART_ITEMS, CART_STEP_PAYMENT_COMPLETE, CLIENT_SECRET_CHECK_TRANSACTION, CLIENT_SECRET_ZERO_DOLLAR_TRANSACTION } from '../../utils/PaymentsConstants';

import usePaymentsShoppingCartData from '../../../../state/payments/paymentsShoppingCart/UsePaymentsShoppingCartData';
import useShoppingCartLoadingModalData from '../../../../state/payments/paymentsShoppingCart/shoppingCartLoadingModal/UseShoppingCartLoadingModalData';
import usePaymentsData from '../../../../state/payments/UsePaymentsData';

const useCheckout = () => {
  const { setCartChanged } = usePaymentsData();
  const { setShoppingCartError } = useShoppingCartLoadingModalData();
  const { paymentsShoppingCartState, checkoutState, updatePaymentsShoppingCartStep, postCheckout, postPaymentComplete,
    resetPaymentsShoppingCartState
  } = usePaymentsShoppingCartData();

  const onTransactionComplete = (transactionObjData) => {
    const checkoutPageData = {
      ...checkoutState.objData,
      ...transactionObjData
    };

    updatePaymentsShoppingCartStep(CART_STEP_PAYMENT_COMPLETE, checkoutPageData, 'checkoutPageData');
  };

  const onCancelClicked = () => {
    updatePaymentsShoppingCartStep(CART_STEP_CART_ITEMS);
  };

  const onBackToCartClicked = () => {
    resetPaymentsShoppingCartState();
  };

  useEffect(() => {
    const cartItemsPageData = paymentsShoppingCartState.cartItemsPageData;

    if (cartItemsPageData) {
      const postCheckoutPromise = postCheckout(cartItemsPageData.selectedCartItemDetailIds, cartItemsPageData.foundationDonation, cartItemsPageData.coverTechnologyFee, cartItemsPageData.swimmingWorldOptIn, cartItemsPageData.payByCheck, cartItemsPageData.personId);

      if (postCheckoutPromise ?? false) {
        postCheckoutPromise.then((newState) => {
          if (newState ?? false) {
            if (newState.objData?.isUserLoggedIn === false) {
              setShoppingCartError(true, 'Session Error', 'Your session has expired. Please log back in to complete your transaction.', true)
            } else if (newState.objData?.clientSecret === CLIENT_SECRET_CHECK_TRANSACTION || newState.objData?.clientSecret === CLIENT_SECRET_ZERO_DOLLAR_TRANSACTION) {
              setCartChanged(true);
            }
          }
        }).catch(() => {
          setShoppingCartError(true, 'Check Out Error', 'An error occurred while trying to check out.')
        });
      }
    } else {
      setShoppingCartError(true, 'Check Out Error', 'No cart items could be found for check out.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: checkoutState.isObjLoading,
    clientSecret: checkoutState.objData?.isUserLoggedIn === false ? null : checkoutState.objData?.clientSecret,
    orderId: checkoutState.objData?.orderId,
    totalAmount: checkoutState.objData?.paymentAmount,
    onTransactionComplete,
    resultPromise: postPaymentComplete,
    onCancelClicked,
    onBackToCartClicked
  };
};

export default useCheckout;