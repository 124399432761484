import {
  PROVIDER_INFO_NAME, PRIMARY_CONTACT_NAME, FACILITIES_NAME, SUBMIT_NAME
} from "../../views/learnToSwimRegistration/components/leftNav/LearnToSwimRegistrationLeftNavConstants";
import NavLinks from "../../views/learnToSwimRegistration/utils/NavLinks";

const INITIAL_OBJ_DATA = {
  entityName: '',
  taxId: ''
};

const INITIAL_ARRAY_DATA = [
  {
    name: PROVIDER_INFO_NAME,
    displayName: 'Provider Info',
    path: NavLinks.LEARN_TO_SWIM_REGISTRATION_PROVIDER_INFO,
    isCurrent: true,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: true,
    prerequisites: []
  }, {
    name: PRIMARY_CONTACT_NAME,
    displayName: 'Primary Contact',
    path: NavLinks.LEARN_TO_SWIM_REGISTRATION_PRIMARY_CONTACT,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [PROVIDER_INFO_NAME]
  }, {
    name: FACILITIES_NAME,
    displayName: 'Facilities',
    path: NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [PROVIDER_INFO_NAME, PRIMARY_CONTACT_NAME]
  }, {
    name: SUBMIT_NAME,
    displayName: 'Submit',
    path: NavLinks.LEARN_TO_SWIM_REGISTRATION_SUBMIT,
    isCurrent: false,
    isComplete: false,
    isVisited: false,
    arePrerequisitesDone: false,
    prerequisites: [PROVIDER_INFO_NAME, PRIMARY_CONTACT_NAME, FACILITIES_NAME]
  }
];

const LearnToSwimRegistrationLeftNavData = {
  INITIAL_OBJ_DATA,
  INITIAL_ARRAY_DATA
};

export default LearnToSwimRegistrationLeftNavData;