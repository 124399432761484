import { useEffect } from 'react';

import validate from './ProviderInfoFormValidation';

import useLearnToSwimClubsData from '../../../../state/learnToSwimClubs/UseLearnToSwimClubsData';
import useLearnToSwimCurriculumData from '../../../../state/learnToSwimCurriculum/UseLearnToSwimCurriculumData';
import useLearnToSwimLogoData from '../../../../state/learnToSwimLogo/UseLearnToSwimLogoData';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  entityName: '',
  tradeName: '',
  taxId: '',
  learnToSwimCurriculumId: Constants.DEFAULT_ID,
  learnToSwimCurriculumName: '--',
  website: '',
  offersFinancialAssistance: '',
  currentLTSGrantRecipient: '',
  affiliatedWithUsasClub: '',
  usasClubId: '',
  usasClubName: '',
  logoUrl: '',
  logoFile: {}
};

const useProviderInfoForm = (onSubmitFormCallback, providerObj) => {
  const { ltsClubsState } = useLearnToSwimClubsData();
  const { ltsCurriculumState } = useLearnToSwimCurriculumData();
  const { ltsLogoState, postLTSLogo } = useLearnToSwimLogoData();
  const { formState, errorState, isDirty, setIsDirty, setFormState, handleSubmit, updateFormState, onValueTextPairChanged,
    resetForm, setErrors, setFormData
  } = UseForm(INITIAL_FORM_STATE, localSubmitFormCallback, validate);

  const onFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, logoFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_JPG) {
        updateFormState('logoFile', selectedFile);
      } else {
        setErrors({ ...errorState, logoFile: 'File type not permitted. Please provide a (*.jpg) file.' });
      }
    }
  };

  const onFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      logoFile: {},
      logoUrl: ''
    });
  };

  const onFileDownload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    window.open(formState.logoUrl);
  };

  useEffect(() => {
    if (providerObj && ltsClubsState.areItemsLoaded === true && ltsCurriculumState.areItemsLoaded === true) {
      let usasClub = null;
      let learnToSwimCurriculum = null;

      if (providerObj.affiliatedWithUsasClub === true && providerObj.usasClubId) {
        usasClub = ltsClubsState.items.find(x => x.id === providerObj.usasClubId);
      }

      if (providerObj.learnToSwimCurriculumId > 0) {
        learnToSwimCurriculum = ltsCurriculumState.items.find(x => x.id === providerObj.learnToSwimCurriculumId);
      }

      setFormData({
        ...formState,
        entityName: providerObj.entityName || '',
        tradeName: providerObj.tradeName || '',
        taxId: providerObj.taxId || '',
        learnToSwimCurriculumId: learnToSwimCurriculum?.id || Constants.DEFAULT_ID,
        learnToSwimCurriculumName: learnToSwimCurriculum?.name || '--',
        website: providerObj.website || '',
        offersFinancialAssistance: providerObj.offersFinancialAssistance === true || providerObj.offersFinancialAssistance === false
          ? providerObj.offersFinancialAssistance
          : '',
        currentLTSGrantRecipient: providerObj.currentLTSGrantRecipient === true || providerObj.currentLTSGrantRecipient === false
          ? providerObj.currentLTSGrantRecipient
          : '',
        affiliatedWithUsasClub: providerObj.affiliatedWithUsasClub === true || providerObj.affiliatedWithUsasClub === false
          ? providerObj.affiliatedWithUsasClub
          : '',
        usasClubId: usasClub?.id || '',
        usasClubName: usasClub?.name || '',
        logoUrl: providerObj.logo || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerObj, ltsClubsState.areItemsLoaded, ltsCurriculumState.areItemsLoaded]);

  //TODO better way to do this?
  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  function localSubmitFormCallback(formState) {
    if (formState.logoFile?.size > 0) {
      const postLTSLogoPromise = postLTSLogo(formState.logoFile);

      if (postLTSLogoPromise !== null) {
        postLTSLogoPromise.then((newState) => {
          if (newState !== null) {
            onSubmitFormCallback({
              ...formState,
              logoUrl: newState.objData?.uploadUrl
            });
          }
        }).catch((e) => {
          //TODO state error
        });
      }
    } else {
      onSubmitFormCallback(formState);
    }
  };

  return {
    isLoading: ltsClubsState.isArrayLoading || ltsCurriculumState.isArrayLoading,
    isSaving: ltsLogoState.isObjLoading,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    resetForm,
    onFileLoad,
    onFileUnload,
    onFileDownload
  };
};

export default useProviderInfoForm;