import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import { PRIMARY_CONTACT_NAME } from "../components/leftNav/LearnToSwimRegistrationLeftNavConstants";

import { createProviderObjFromPrimaryContactForm } from "../utils/LearnToSwimRegistrationUtils";
import NavLinks from "../utils/NavLinks";

import useLearnToSwimRegistrationData from "../../../state/learnToSwimRegistration/UseLearnToSwimRegistrationData";
import useLearnToSwimRegistrationLeftNavData from "../../../state/learnToSwimRegistrationLeftNav/UseLearnToSwimRegistrationLeftNavData";

import usePersonData from "../../../../common/state/person/UsePersonData";

const useLearnToSwimRegistrationPrimaryContact = () => {
  const navigate = useNavigate();
  const { ltsProviderRegistrationState, putLTSProviderRegistration } = useLearnToSwimRegistrationData();
  const { updateCurrentLeftNavData } = useLearnToSwimRegistrationLeftNavData();
  const { personState, getPotentialPersonDupes } = usePersonData();
  const [newPersonState, setNewPersonState] = useState({});
  const [displayDupModalState, setDisplayDupModalState] = useState(false);

  const onPersonConfirmed = (personObj) => {
    const providerObj = createProviderObjFromPrimaryContactForm(personObj, ltsProviderRegistrationState.objData);
    const putLTSProviderRegistrationPromise = putLTSProviderRegistration(providerObj);

    updateCurrentLeftNavData(PRIMARY_CONTACT_NAME, true);

    if (putLTSProviderRegistrationPromise !== null) {
      putLTSProviderRegistrationPromise.then((newState) => {
        if (newState !== null) {
          navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_FACILITIES);
        }
      }).catch((e) => {
        //TODO context error
        updateCurrentLeftNavData(PRIMARY_CONTACT_NAME, false);
      });
    }
  };

  const onDupModalCancelClicked = () => {
    setDisplayDupModalState(false);
  };

  const onSubmitFormCallback = (formState) => {
    setNewPersonState({ ...formState });

    getPotentialPersonDupes(formState.firstName, formState.lastName, formState.birthDate);
  };

  const onBackClicked = () => {
    navigate(NavLinks.LEARN_TO_SWIM_REGISTRATION_PROVIDER_INFO);
  };

  useEffect(() => {
    if (personState.isArrayLoaded === true) {
      setDisplayDupModalState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.isArrayLoaded]);

  return {
    isLoading: ltsProviderRegistrationState.isObjLoading || personState.isArrayLoading,
    dupPersons: personState.arrayData,
    ltsProviderRegistrationState,
    displayDupModalState,
    newPersonState,
    onSubmitFormCallback,
    onBackClicked,
    onPersonConfirmed,
    onDupModalCancelClicked
  };
};

export default useLearnToSwimRegistrationPrimaryContact;