import useRegistrationPrimaryContactForm from "./UseRegistrationPrimaryContactForm";

import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import InternationalPhoneInput from "../../../../../common/components/inputs/InternationalPhoneInput";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";

const RegistrationPrimaryContactForm = ({ submitButtonText = 'Save & Continue', secondaryButtonText = 'Back',
  providerObj, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useRegistrationPrimaryContactForm(onSubmitFormCallback, providerObj);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <Input
            label="First Name*"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { updateFormState('firstName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label="Last Name*"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { updateFormState('lastName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <DatePicker
            label="Birth Date*"
            name="birthDate"
            value={formState.birthDate}
            error={errorState.birthDate}
            message={errorState.birthDate}
            onChange={(value) => { updateFormState('birthDate', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label="Email*"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { updateFormState('email', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <InternationalPhoneInput
            label="Phone Number*"
            name="phoneNumber"
            value={formState.phoneNumber}
            error={errorState.phoneNumber}
            message={errorState.phoneNumber}
            onChange={(value) => { updateFormState('phoneNumber', value); }} />
        </div>
      </div>
      <div className="row pull-right">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default RegistrationPrimaryContactForm;