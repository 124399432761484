import { useEffect } from 'react';

import usePaymentsData from '../../../../state/payments/UsePaymentsData';
import usePaymentsShoppingCartData from '../../../../state/payments/paymentsShoppingCart/UsePaymentsShoppingCartData';
import useShoppingCartLoadingModalData from '../../../../state/payments/paymentsShoppingCart/shoppingCartLoadingModal/UseShoppingCartLoadingModalData';

const usePaymentComplete = () => {
  const { paymentReceiptState, getPaymentReceipt, setCartChanged } = usePaymentsData();
  const { setShoppingCartError } = useShoppingCartLoadingModalData();
  const { paymentsShoppingCartState, resetPaymentsShoppingCartState } = usePaymentsShoppingCartData();

  const onBackToCartClicked = () => {
    resetPaymentsShoppingCartState();
  };

  const onDownloadReceiptClicked = () => {
    if (paymentsShoppingCartState.checkoutPageData?.stripeReceiptNumber) {
      const getPaymentReceiptPromise = getPaymentReceipt(paymentsShoppingCartState.checkoutPageData.stripeReceiptNumber);

      if (getPaymentReceiptPromise ?? false) {
        getPaymentReceiptPromise.catch(() => {
          setShoppingCartError(true, 'Receipt Error', 'An error occurred while trying to retrieve your receipt.');
        });
      }
    }
  };

  useEffect(() => {
    setCartChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: paymentReceiptState.isObjLoading,
    hasReceipt: paymentsShoppingCartState.checkoutPageData?.stripeReceiptNumber ? true : false,
    isPending: paymentsShoppingCartState.checkoutPageData?.paymentPending === true,
    onBackToCartClicked,
    onDownloadReceiptClicked
  };
};

export default usePaymentComplete;