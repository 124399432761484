import { isValidLink } from "../../utils/LearnToSwimRegistrationUtils";

import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (!(formState.learnToSwimCurriculumId > 0)) {
    errors.learnToSwimCurriculumId = 'Learn To Swim Curriculum is required';
  }

  if (formState.website.trim() === '') {
    errors.website = 'Website is required';
  } else if (!isValidLink(formState.website)) {
    errors.website = `Website must begin with 'http://' or 'https://'`;
  }

  if (formState.offersFinancialAssistance !== true && formState.offersFinancialAssistance !== false) {
    errors.offersFinancialAssistance = 'Must select yes or no for this field';
  }

  if (formState.currentLTSGrantRecipient !== true && formState.currentLTSGrantRecipient !== false) {
    errors.currentLTSGrantRecipient = 'Must select yes or no for this field';
  }

  if (formState.affiliatedWithUsasClub !== true && formState.affiliatedWithUsasClub !== false) {
    errors.affiliatedWithUsasClub = 'Must select yes or no for this field';
  }

  if (formState.affiliatedWithUsasClub === true) {
    if (formState.usasClubId === '' || formState.usasClubId === Constants.DEFAULT_ID) {
      if (formState.usasClubName !== '') {
        errors.usasClubId = 'Must select a valid USAS Club';
      } else {
        errors.usasClubId = 'Affiliated USAS Club is required';
      }
    }
  }

  return errors;
};

const RegistrationProviderInfoFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default RegistrationProviderInfoFormValidation;