import { isValidLink } from '../../utils/PreCompUtils';

import { isValidAddressLine, isValid5DigitZip, isValid9DigitZip, validateAddress } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const localValidate = async (formState) => {
  let errors = {};

  let addressError = false;

  if (formState.address1.trim() !== '') {
    if (!isValidAddressLine(formState.address1.trim())) {
      addressError = true;
      errors.address1 = 'Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  } else {
    addressError = true;
    errors.address1 = 'Address Line 1 is required';
  }

  if (formState.address2.trim() !== '') {
    if (!isValidAddressLine(formState.address2.trim())) {
      addressError = true;
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }
  
  if (formState.tradeName.trim() === '') {
    addressError = true;
    errors.tradeName = 'Trade Name is required';
  } else if (formState.tradeName.trim().length > 200) {
    addressError = true;
    errors.tradeName = 'Trade Name cannot exceed 200 characters';
  }
  
  if (formState.city.trim() !== '') {
    if (!isValidAddressLine(formState.city.trim())) {
      addressError = true;
      errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  } else {
    addressError = true;
    errors.city = 'City is required';
  }

  //State
  if (ToIntIfInt(formState.stateCode) === Constants.DEFAULT_ID || formState.stateCode.trim() === '') {
    addressError = true;
    errors.stateCode = 'State is required and must be valid';
  }
  
  //Zip
  if (formState.postalCode.trim().length === 0) {
    addressError = true;
    errors.postalCode = 'Zip Code is required';
  } else if (!isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  if (addressError === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1 !== formState.validatedAddress.address1
        || formState.address2 !== formState.validatedAddress.address2
        || formState.city !== formState.validatedAddress.city
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode !== formState.validatedAddress.postalCode
      )
    ) {
      if (formState.address1 !== formState.validatedAddress.address1) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2 !== formState.validatedAddress.address2) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city !== formState.validatedAddress.city) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode !== formState.validatedAddress.postalCode) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  if (!(formState.businessEntityTypeId > 0)) {
    errors.businessEntityTypeId = 'Business Entity Type is required';
  }

  if (formState.preCompOrganizationAffiliation.length < 1) {
    errors.preCompOrganizationAffiliation = 'Must select at least one Organization Affiliation';
  }

  if (formState.website.trim() !== '') {
    if (!isValidLink(formState.website)) {
      errors.website = `Website must begin with 'http://' or 'https://'`;
    }
  }

  return errors;
};

const PreCompOrganizationBasicInfoFormValidation = async (formState) => {
  let errors = await localValidate(formState);

  return errors ? errors : {};
};

export default PreCompOrganizationBasicInfoFormValidation;