import React, { Fragment } from 'react';

import useLearnToSwimRegistrationSubmit from './UseLearnToSwimRegistrationSubmit';

import LearnToSwimRegistrationLeftNav from '../components/leftNav/LearnToSwimRegistrationLeftNav';
import RegistrationSubmitForm from '../components/forms/RegistrationSubmitForm';
import { SUBMIT_NAME } from '../components/leftNav/LearnToSwimRegistrationLeftNavConstants';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimRegistrationSubmit = () => {
  const {
    isLoading,
    modalState,
    ltsProviderRegistrationState,
    onSubmitFormCallback,
    onBackClicked,
    onModalExitClicked
  } = useLearnToSwimRegistrationSubmit();

  return (
    <Fragment>
      <LearnToSwimRegistrationLeftNav viewName={SUBMIT_NAME} viewTitle={'Submit'}>
        <RegistrationSubmitForm
          providerObj={ltsProviderRegistrationState.objData}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={onBackClicked} />
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title="Registration Complete"
          displayPopUp={modalState.displayModal}
          onModalCanceled={onModalExitClicked}>
          <div className="row">
            <div className="col-xs-12">
              <p>Thank you for registering for USA Swimming's Find a Swim Lesson search engine. USA Swimming staff will review your submission and, upon approval, your program will populate as a participating Swim Lesson Provider.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onModalExitClicked}>Exit Registration</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={isLoading} />
      </LearnToSwimRegistrationLeftNav>
    </Fragment>
  );
};

export default LearnToSwimRegistrationSubmit;