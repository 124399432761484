import { useEffect, useState } from 'react';

import {
  calculateTechFee, createInitiateCartItemsGridRecurse, createUpdatedCartItemsGridRecurse,
  toggleIsSelectedRecurse
} from '../../utils/PaymentsUtils';
import { CART_STEP_CHECKOUT } from '../../utils/PaymentsConstants';

import usePaymentsData from '../../../../state/payments/UsePaymentsData';
import usePaymentsShoppingCartData from '../../../../state/payments/paymentsShoppingCart/UsePaymentsShoppingCartData';
import useShoppingCartLoadingModalData from '../../../../state/payments/paymentsShoppingCart/shoppingCartLoadingModal/UseShoppingCartLoadingModalData';

const INITIAL_STATE = {
  cartItems: [],
  selectedCartItemDetailIds: [],
  subTotal: 0.0, // total of all 'selected' cartItems
  totalAmount: 0.0, // is subTotal + foundationDonation + technologyFee(if coverTechnologyFee = true)
  foundationDonation: '0.00',
  technologyFee: 0.0,
  swimmingWorldOptIn: false,
  coverTechnologyFee: false
};

const INITIAL_DELETE_ITEM_POPUP_STATE = {
  displayPopUp: false,
  cartItemDetailId: '',
  itemDescription: '',
  itemPrice: 0
};

const useCartItems = () => {
  const { setCartChanged } = usePaymentsData();
  const { setShoppingCartError } = useShoppingCartLoadingModalData();
  const { paymentsShoppingCartState, cartItemsState, getCart, deleteCartItem, updatePaymentsShoppingCartStep
  } = usePaymentsShoppingCartData();
  const [state, setState] = useState(INITIAL_STATE);
  const [deleteItemPopupState, setDeleteItemPopupState] = useState(INITIAL_DELETE_ITEM_POPUP_STATE);
  const [displayPayByCheckModalState, setDisplayPayByCheckModalState] = useState(false);

  const onStateValueChanged = (fieldName, newValue) => {
    const newState = {
      ...state,
      [fieldName]: newValue
    };

    updateState(newState, cartItemsState.objData?.technologyFeePercent, cartItemsState.objData?.technologyFeePlus);
  };

  const onCartItemSelectedChanged = (cartItem) => {
    if (cartItem.cartItemId) {
      const [cartItems, subTotal] = toggleIsSelectedRecurse(state.cartItems, cartItem.cartItemId);

      updateState({
        ...state,
        cartItems,
        subTotal
      }, cartItemsState.objData?.technologyFeePercent, cartItemsState.objData?.technologyFeePlus);
    }
  };

  const onDeleteItem = (cartItem) => {
    if (cartItem?.cartItemDetailId) {
      setDeleteItemPopupState({
        ...deleteItemPopupState,
        displayPopUp: true,
        cartItemDetailId: cartItem.cartItemDetailId,
        itemDescription: cartItem.itemDescription,
        itemPrice: cartItem.itemPrice
      });
    }
  };

  const onDeleteItemPopupConfirmed = () => {
    if (deleteItemPopupState.cartItemDetailId) {
      const deleteCartItemPromise = deleteCartItem(deleteItemPopupState.cartItemDetailId);

      if (deleteCartItemPromise ?? false) {
        deleteCartItemPromise.then((newState) => {
          if (newState ?? false) {
            const unselectedCartItemIds = state.cartItems.filter((value) => value.isSelected === false).map((value) => value.cartItemId);
            const [cartItems, subTotal] = createUpdatedCartItemsGridRecurse(newState.objData?.items, unselectedCartItemIds);

            updateState({
              ...state,
              cartItems,
              subTotal
            }, newState.objData?.technologyFeePercent, newState.objData?.technologyFeePlus);
            setCartChanged(true);
          }
        }).catch(() => {
          setShoppingCartError(true, 'Item Deletion Error', 'An error occurred while trying to delete this item.')
        });
      }
    }

    setDeleteItemPopupState(INITIAL_DELETE_ITEM_POPUP_STATE);
  };

  const onDeleteItemPopupCanceled = () => {
    setDeleteItemPopupState(INITIAL_DELETE_ITEM_POPUP_STATE);
  };

  const onCheckoutClicked = () => {
    const selectedCartItemDetailIds = state.cartItems.filter((value) => value.isSelected === true).map((value) => value.cartItemDetailId);

    const cartItemsPageData = {
      ...state,
      payByCheck: false,
      selectedCartItemDetailIds
    };

    updatePaymentsShoppingCartStep(CART_STEP_CHECKOUT, cartItemsPageData, 'cartItemsPageData')
  };

  const onPayByCheckClicked = () => {
    setDisplayPayByCheckModalState(true);
  };

  const onPayByCheckModalFormSubmitted = (formState) => {
    if (formState.personId) {
      const selectedCartItemDetailIds = state.cartItems.filter((value) => value.isSelected === true).map((value) => value.cartItemDetailId);

      const cartItemsPageData = {
        ...state,
        selectedCartItemDetailIds,
        payByCheck: true,
        personId: formState.personId
      };

      updatePaymentsShoppingCartStep(CART_STEP_CHECKOUT, cartItemsPageData, 'cartItemsPageData')
    }
  };

  const onPayByCheckModalCanceled = () => {
    setDisplayPayByCheckModalState(false);
  };

  useEffect(() => {
    if (cartItemsState.isObjLoading !== true && cartItemsState.isObjLoaded !== true) {
      const getCartPromise = getCart();

      if (getCartPromise ?? false) {
        getCartPromise.then((newState) => {
          if (newState ?? false) {
            // is revisit
            if (Array.isArray(paymentsShoppingCartState.cartItemsPageData?.cartItems)) {
              const previousPageData = paymentsShoppingCartState.cartItemsPageData;
              const unselectedCartItemIds = previousPageData.cartItems.filter((value) => value.isSelected === false).map((value) => value.cartItemId);
              const [cartItems, subTotal] = createUpdatedCartItemsGridRecurse(newState.objData?.items, unselectedCartItemIds);

              updateState({
                ...state,
                ...previousPageData,
                cartItems,
                subTotal
              }, newState.objData?.technologyFeePercent, newState.objData?.technologyFeePlus);
            } else { // first visit
              const [cartItems, subTotal] = createInitiateCartItemsGridRecurse(newState.objData?.items || []);

              updateState({
                ...state,
                cartItems,
                subTotal
              }, newState.objData?.technologyFeePercent, newState.objData?.technologyFeePlus);
            }
          }
        }).catch(() => {
          setShoppingCartError(true, 'Shopping Cart Error', 'An error occurred while trying to retrieve your cart.')
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // use this instead of setState to ensure dependent fields are calculated correctly
  function updateState(newState, technologyFeePercent = undefined, technologyFeePlus = undefined) {
    const amount = newState.subTotal + parseFloat(newState.foundationDonation);
    const technologyFee = calculateTechFee(amount, technologyFeePercent, technologyFeePlus);
    const totalAmount = newState.coverTechnologyFee === true
      ? amount + technologyFee
      : amount;

    setState({
      ...state,
      ...newState,
      technologyFee,
      totalAmount
    });
  }

  return {
    isLoading: cartItemsState.isObjLoading === true,
    isProcessingOrder: cartItemsState.objData?.processingOrder === true,
    state,
    displayPayByCheckModalState,
    deleteItemPopupState,
    onStateValueChanged,
    onCartItemSelectedChanged,
    onDeleteItem,
    onDeleteItemPopupConfirmed,
    onDeleteItemPopupCanceled,
    onCheckoutClicked,
    onPayByCheckClicked,
    onPayByCheckModalCanceled,
    onPayByCheckModalFormSubmitted
  };
};

export default useCartItems;