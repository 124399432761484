import { Fragment } from 'react';

import YesNoSwitch from '../../../../yesNoSwitch/YesNoSwitch';
import Input from '../../../../inputs/Input';
import InternationalPhoneInput from '../../../../inputs/InternationalPhoneInput';
import StatesCombobox from '../../../../comboboxes/statesCombobox/StatesCombobox';
import ClubAffiliationTypeDropdown from '../../../../dropdowns/clubAffTypeDropdown/ClubAffiliationTypeDropdown';
import CountryCombobox from '../../../../comboboxes/countryCombobox/CountryCombobox';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const FacilityForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="International?*"
          name="isInternational"
          checked={formState.isInternational}
          error={errorState.isInternational}
          message={errorState.isInternational}
          onChange={(value) => { onFormValueChanged('isInternational', value) }} />
      </div>
    </div>
    <div className='row'>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Facility Name*"
          name="facilityName"
          value={formState.facilityName}
          error={errorState.facilityName}
          message={errorState.facilityName}
          onChange={(value) => { onFormValueChanged('facilityName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Address Line 1*"
          name="address1"
          value={formState.address1}
          error={errorState.address1}
          message={errorState.address1}
          onChange={(value) => { onFormValueChanged('address1', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Address Line 2"
          name="address2"
          value={formState.address2}
          error={errorState.address2}
          message={errorState.address2}
          onChange={(value) => { onFormValueChanged('address2', value) }} />
      </div>
      <div className={formState.isInternational === true ? global.DisplayComponent : global.HideComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <CountryCombobox
            label="Country*"
            name="country"
            valueToMatch={formState.countryName}
            error={errorState.country}
            message={errorState.country}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'country', newValueLabel, 'countryName', e); }} />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="City*"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className={formState.isInternational === true ? global.DisplayComponent : global.HideComponent}>
          <Input
            label="Region*"
            name="stateCode"
            value={formState.stateCode}
            error={errorState.stateCode}
            message={errorState.stateCode}
            onChange={(value) => { onFormValueChanged('stateCode', value) }} />
        </div>
        <div className={formState.isInternational === false ? global.DisplayComponent : global.HideComponent}>
          <StatesCombobox
            label="State*"
            name="stateCode"
            valueToMatch={formState.stateName}
            error={errorState.stateCode}
            message={errorState.stateCode}
            isUSA={true}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'stateCode', newValueLabel, 'stateName', e); }} />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label={formState.isInternational === true ? "Postal Code*" : "Zip Code*"}
          name="postalCode"
          value={formState.postalCode}
          error={errorState.postalCode}
          message={errorState.postalCode}
          onChange={(value) => { onFormValueChanged('postalCode', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Facility Contact Name*"
          name="contactName"
          value={formState.contactName}
          error={errorState.contactName}
          message={errorState.contactName}
          onChange={(value) => { onFormValueChanged('contactName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Facility Contact Email Address*"
          name="contactEmailAddress*"
          value={formState.contactEmailAddress}
          error={errorState.contactEmailAddress}
          message={errorState.contactEmailAddress}
          onChange={(value) => { onFormValueChanged('contactEmailAddress', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <InternationalPhoneInput
          label="Facility Contact Phone Number*"
          name="contactPhoneNumber"
          value={formState.contactPhoneNumber}
          error={errorState.contactPhoneNumber}
          message={errorState.contactPhoneNumber}
          onChange={(value) => { onFormValueChanged('contactPhoneNumber', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ClubAffiliationTypeDropdown
          label="Facility Organization Affiliation*"
          name="clubAffiliationId"
          value={formState.clubAffiliationId}
          error={errorState.clubAffiliationId}
          message={errorState.clubAffiliationId}
          onChange={(value, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(value, 'clubAffiliationId', newValueLabel, 'clubAffiliationName')
          }}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="Facility Can Host Meet*"
          name="hostMeet"
          checked={formState.hostMeet}
          error={errorState.hostMeet}
          message={errorState.hostMeet}
          onChange={(value) => { onFormValueChanged('hostMeet', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Facility Altitude (ft)*"
          name="altitude"
          type="number"
          value={formState.altitude}
          error={errorState.altitude}
          message={errorState.altitude}
          onChange={(value) => { onFormValueChanged('altitude', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="Has Met Depth Requirement*"
          name="depthRequirementMet"
          checked={formState.depthRequirementMet}
          error={errorState.depthRequirementMet}
          message={errorState.depthRequirementMet}
          onChange={(value) => { onFormValueChanged('depthRequirementMet', value) }} />
      </div>
    </div>
  </Fragment>
);

export default FacilityForm;