import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import validate from '../components/forms/SATSearchFormValidation';

import useSwimAThonData from '../../../state/swimAThon/UseSwimAThonData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const INITIAL_VIEW_STATE = {
  isItemSelected: false
};

const TAXONOMIES = ['SwimAThonDetail'];
const SCOPE = 'ClubSwimAThon';

const useClubSwimAThonSearch = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { environmentVariableState, USAS_ORGANIZATION_ID, SWIM_A_THON_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { satState, satFiltersState, searchSAT, getSAT } = useSwimAThonData();
  const { formState, errorState, setFormState, handleSubmit, updateFormState, setErrors, onValueTextPairChanged, setIsDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [organizationOptionsState, setOrganizationOptionsState] = useState([]);

  function submitFormCallback(formState) {
    if (satState.isArrayLoading === false) {
      searchSAT({ organizationId: formState.organizationId, clubName: formState.clubName });
    }
  };

  const onEditSATOrgUnit = (e, orgUnitId, orgUnitName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (orgUnitId && orgUnitName) {
      getSAT(orgUnitId, orgUnitName, 'SATSearch');
      getContextSecurity(orgUnitId, TAXONOMIES, SCOPE);

      setState({
        ...state,
        isItemSelected: true
      });
    }
  };

  const onClearFormClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({ ...getInitialFormState() });
    setErrors({});
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      setOrganizationOptionsState([
        { id: USAS_ORGANIZATION_ID, name: 'USA Swimming', value: USAS_ORGANIZATION_ID },
        { id: SWIM_A_THON_ORGANIZATION_ID, name: 'Swim-a-Thon (Non Member)', value: SWIM_A_THON_ORGANIZATION_ID },
      ]);

      setFormState({
        ...formState,
        organizationId: USAS_ORGANIZATION_ID,
        organizationName: 'USA Swimming'
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState.isLoaded]);

  useEffect(() => {
    if (Object.keys(satFiltersState.filterObject).length > 0 && organizationOptionsState.length > 0) {
      const oldSearchValues = JSON.parse(JSON.stringify(satFiltersState.filterObject));

      setFormState({
        ...formState,
        ...oldSearchValues,
        organizationName: organizationOptionsState.find(x => x.id === oldSearchValues.organizationId)?.name || ''
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satFiltersState, organizationOptionsState]);

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (satState.isObjLoading !== true && satState.isObjLoaded === true) {
        if (satState.orgUnitId) {
          navigate(NavLinks.CLUB_SWIMATHON_CONTRACTS);
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, satState]);

  function getInitialFormState() {
    return {
      organizationId: USAS_ORGANIZATION_ID || Constants.DEFAULT_ID,
      organizationName: '',
      clubName: ''
    }
  };

  return {
    satState,
    formState,
    errorState,
    onClearFormClicked,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onEditSATOrgUnit,
    organizationOptionsState
  };
};

export default useClubSwimAThonSearch;