import { Fragment } from "react";

import useLearnToSwimRegistrationStaffDupPopup from "./UseLearnToSwimRegistrationStaffDupPopup";

import ProviderStaffDupGrid from "../../../learnToSwim/components/grids/ProviderStaffDupGrid";

import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const LearnToSwimRegistrationStaffDupPopup = ({ dupArrayData, newPersonObj, onPersonConfirmed, onCancelClicked }) => {
  const {
    dupGridState,
    newGridState,
    onContinueClicked,
    onSelectGridPerson
  } = useLearnToSwimRegistrationStaffDupPopup(dupArrayData, newPersonObj, onPersonConfirmed)

  return (
    <Fragment>
      <PopUpModal
        widthPct={90}
        maxWidth={1020}
        title={"Staff Dupe Check"}
        displayPopUp={dupGridState.length > 0}
        onModalCanceled={onCancelClicked}>
        <p><b>Database Matches Found</b></p>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <ProviderStaffDupGrid
              actionLabel={'Use Existing Person'}
              persons={dupGridState}
              onSelected={onSelectGridPerson} />
          </div>
        </div>
        <p><b>Info Provided By User</b></p>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <ProviderStaffDupGrid
              actionLabel={'Create New Person'}
              persons={newGridState}
              onSelected={onSelectGridPerson} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default LearnToSwimRegistrationStaffDupPopup;