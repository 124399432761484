import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import UseUsasClubThirdPartyInvoicesData from '../../../state/clubThirdPartyInvoices/UseUsasClubThirdPartyInvoicesData';
import UseLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData from '../../../state/lscOfferingByOrgUnitIdAndReportPeriodIdDropdown/UseLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData';
import usePersonPotentialMatchData from '../../../state/personPotentialMatch/UsePersonPotentialMatchData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';
import { compareAlphabetical } from '../../../../common/utils/SortingUtils';
import ToIntIfInt from '../../../../common/utils/ToIntIfInt';

const INITIAL_PERSON_POTENTIAL_MATCH_MODAL_STATE = {
  displayPopUp: false,
  invoice: {},
  showError: false,
  error: ''
};

const INITIAL_CHECK_OUT_CONFIRMATION_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'The Selected Third Party Invoice Has Been Added to the Shopping Cart',
  name: '',
  memberId: '',
  competitionCategory: '',
  birthDate: '',
  submittedOfferingName: '',
  offeringName: ''
};

const useClubThirdPartyInvoicesDetail = (modalState, onModalCanceled) => {
  const navigate = useNavigate();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubThirdPartyInvoiceState, setClubThirdPartyInvoiceState, getThirdPartyInvoiceByThirdPartyClubRegistrationIdData,
    putThirdPartyInvoiceByThirdPartyClubRegistrationIdData, deleteThirdPartyInvoices, confirmThirdPartyInvoicesSave } = UseUsasClubThirdPartyInvoicesData();
  const { lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState } = UseLscOfferingByOrgUnitIdAndReportPeriodIdDropdownData();
  const { personPotentialMatchState, setPersonPotentialMatchState, getPersonPotentialMatches, putPersonPotentialMatches } = usePersonPotentialMatchData();
  const [state, setState] = useState({ showError: false, tryErrorCheck: false, tryUpdateGridWithMemberId: false, thirdPartyClubRegistrationPersonId: '', memberId: '', tryDelete: false, tryCloseEntireModal: false });
  const [gridState, setGridState] = useState({ gridData: [] });
  const [offeringOptions, setOfferingOptions] = useState({ options: [] });
  const [personPotentialMatchModalState, setPersonPotentialMatchModalState] = useState(INITIAL_PERSON_POTENTIAL_MATCH_MODAL_STATE);
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState);
  const [checkOutConfirmationModalState, setCheckOutConfirmationModalState] = useState(INITIAL_CHECK_OUT_CONFIRMATION_MODAL_STATE);

  const onUpdateSelectedOfferingNameValueChange = (e, invoice, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(clubThirdPartyInvoiceState.arrayData));
      const selectedArrayDataIndex = arrayDataCopy.findIndex(x => (x.thirdPartyClubRegistrationPersonId === invoice.thirdPartyClubRegistrationPersonId && x.thirdPartyClubRegistrationPersonOfferingId === invoice.thirdPartyClubRegistrationPersonOfferingId));
      arrayDataCopy[selectedArrayDataIndex].selectedOfferingInstanceId = value;
      arrayDataCopy[selectedArrayDataIndex].selectedOfferingName = valueLabel;
      if (arrayDataCopy[selectedArrayDataIndex].hasDupOffering === true) {
        arrayDataCopy[selectedArrayDataIndex].hasDupOffering = false;
      }
      if (arrayDataCopy[selectedArrayDataIndex].dupOfferingThirdPartyClubRegistrationPersonOfferingId !== null) {
        const selectedDupArrayDataIndex = arrayDataCopy.findIndex(x => (x.thirdPartyClubRegistrationPersonId === invoice.thirdPartyClubRegistrationPersonId && x.thirdPartyClubRegistrationPersonOfferingId === invoice.dupOfferingThirdPartyClubRegistrationPersonOfferingId));
        arrayDataCopy[selectedDupArrayDataIndex].hasDupOffering = false;
        arrayDataCopy[selectedDupArrayDataIndex].dupOfferingThirdPartyClubRegistrationPersonOfferingId = null;
        arrayDataCopy[selectedArrayDataIndex].dupOfferingThirdPartyClubRegistrationPersonOfferingId = null;
      }
      setClubThirdPartyInvoiceState({ ...clubThirdPartyInvoiceState, arrayData: arrayDataCopy });

      const gridDataCopy = JSON.parse(JSON.stringify(gridState.gridData));
      const selectedGridDataIndex = arrayDataCopy.findIndex(x => (x.thirdPartyClubRegistrationPersonId === invoice.thirdPartyClubRegistrationPersonId && x.thirdPartyClubRegistrationPersonOfferingId === invoice.thirdPartyClubRegistrationPersonOfferingId));
      gridDataCopy[selectedGridDataIndex].selectedOfferingInstanceId = value;
      gridDataCopy[selectedGridDataIndex].selectedOfferingName = valueLabel;
      if (gridDataCopy[selectedGridDataIndex].hasDupOffering === true) {
        gridDataCopy[selectedGridDataIndex].hasDupOffering = false;
      }
      if (gridDataCopy[selectedArrayDataIndex].dupOfferingThirdPartyClubRegistrationPersonOfferingId !== null) {
        const selectedDupGridDataIndex = arrayDataCopy.findIndex(x => (x.thirdPartyClubRegistrationPersonId === invoice.thirdPartyClubRegistrationPersonId && x.thirdPartyClubRegistrationPersonOfferingId === invoice.dupOfferingThirdPartyClubRegistrationPersonOfferingId));
        gridDataCopy[selectedDupGridDataIndex].hasDupOffering = false;
        gridDataCopy[selectedDupGridDataIndex].dupOfferingThirdPartyClubRegistrationPersonOfferingId = null;
        gridDataCopy[selectedGridDataIndex].dupOfferingThirdPartyClubRegistrationPersonOfferingId = null;
      }
      setGridState({ ...gridState, gridData: gridDataCopy });
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onModalCanceled();
  };

  const onPersonPotentialMatchModalOpenClicked = (e, invoice) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    getPersonPotentialMatches(invoice?.firstName, invoice?.lastName, formatDate(invoice?.birthDate));
    setPersonPotentialMatchModalState({ ...personPotentialMatchModalState, displayPopUp: true, invoice: invoice });
  };

  const onSelectMemberIdMatch = (memberId, isSelected) => {
    let newPersonPotentialMatchStateArrayData = [];

    for (const option of personPotentialMatchState.arrayData) {
      newPersonPotentialMatchStateArrayData.push({
        ...option,
        isSelected: memberId === option.memberId ? (isSelected ? !isSelected : true) : false
      });
    }

    setPersonPotentialMatchState({ ...personPotentialMatchState, arrayData: newPersonPotentialMatchStateArrayData });
  };

  const onPersonPotentialMatchModalSaveClicked = () => {
    let selectedOption = personPotentialMatchState.arrayData.find(x => x.isSelected === true)
    if (selectedOption) {
      putPersonPotentialMatches(baseUsasClubState.objData?.orgUnitId, personPotentialMatchModalState.invoice?.thirdPartyClubRegistrationId, personPotentialMatchModalState.invoice?.thirdPartyClubRegistrationPersonId, { memberId: selectedOption.memberId });
      setState({ ...state, tryUpdateGridWithMemberId: true, thirdPartyClubRegistrationPersonId: personPotentialMatchModalState.invoice?.thirdPartyClubRegistrationPersonId, memberId: selectedOption.memberId })
    }
    else {
      setPersonPotentialMatchModalState({ ...personPotentialMatchModalState, showError: true, error: 'You have not selected a match. Please select an option in order to be able to save.' });
    }
  };

  const onPersonPotentialMatchModalCancelClicked = () => {
    setPersonPotentialMatchModalState(INITIAL_PERSON_POTENTIAL_MATCH_MODAL_STATE);
  };

  const onDeleteClicked = (invoice) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      thirdPartyClubRegistrationPersonOfferingId: invoice.thirdPartyClubRegistrationPersonOfferingId,
      name: (invoice.firstName || invoice.middleName || invoice.lastName) ? `${invoice.firstName || ''}  ${invoice.preferredName !== '' && invoice.preferredName !== invoice.firstName ? '"' + invoice.preferredName + '"' : ''} ${invoice.middleName || ''} ${invoice.lastName || ''}` : '',
      memberId: invoice.memberId || '',
      competitionCategory: invoice.competitionGenderTypeName || '',
      birthDate: invoice.birthDate ? formatDate(invoice.birthDate) : '',
      submittedOfferingName: invoice.submittedOfferingName || '',
      offeringName: invoice.selectedOfferingName || ''
    });
  };

  const onModalDeleteClicked = () => {
    if (clubThirdPartyInvoiceState.arrayData.find(x => x.selectedOfferingName !== x.submittedOfferingName)) {
      const thirdPartyInvoiceArray = clubThirdPartyInvoiceState.arrayData.map(invoice => {
        return ({
          thirdPartyClubRegistrationPersonOfferingId: invoice.thirdPartyClubRegistrationPersonOfferingId,
          thirdPartyClubRegistrationPersonId: invoice.thirdPartyClubRegistrationPersonId,
          thirdPartyClubRegistrationId: invoice.thirdPartyClubRegistrationId,
          memberId: invoice.memberId,
          firstName: invoice.firstName,
          lastName: invoice.lastName,
          middleName: invoice.middleName,
          preferredName: invoice.preferredName,
          birthDate: invoice.birthDate,
          competitionGenderTypeName: invoice.competitionGenderTypeName,
          emailAddress: invoice.emailAddress,
          submittedOfferingName: invoice.submittedOfferingName,
          offeringName: invoice.selectedOfferingInstanceId !== Constants.DEFAULT_ID ? invoice.selectedOfferingName : null,
          offeringInstanceId: invoice.selectedOfferingInstanceId !== Constants.DEFAULT_ID ? invoice.selectedOfferingInstanceId?.toString()?.includes('offeringInstanceCouponId') === true ? ToIntIfInt(invoice.selectedOfferingInstanceId.substring(0, invoice.selectedOfferingInstanceId.indexOf("o"))) : invoice.selectedOfferingInstanceId : null,
          offeringInstanceCouponId: invoice.selectedOfferingInstanceId !== Constants.DEFAULT_ID ? invoice.selectedOfferingInstanceId?.toString()?.includes('offeringInstanceCouponId') === true ? ToIntIfInt(invoice.selectedOfferingInstanceId.substring(invoice.selectedOfferingInstanceId.indexOf("o"), invoice.selectedOfferingInstanceId.length)?.replace('offeringInstanceCouponId', '')) : null : null,
          isClubOffering: invoice.isClubOffering
        })
      })
      putThirdPartyInvoiceByThirdPartyClubRegistrationIdData(baseUsasClubState.objData.orgUnitId, modalState?.invoice?.thirdPartyClubRegistrationId,
        thirdPartyInvoiceArray);
    }
    setState({ ...state, tryDelete: true });
  };

  const onModalCancelClicked = () => {
    setDeleteModalState(getInitialDeleteModalState);
  };

  const onDeleteInvoice = (thirdPartyClubRegistrationPersonOfferingId) => {
    deleteThirdPartyInvoices(thirdPartyClubRegistrationPersonOfferingId);
  };

  const onCheckOutClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const thirdPartyInvoiceArray = clubThirdPartyInvoiceState.arrayData.map(invoice => {
      return ({
        thirdPartyClubRegistrationPersonOfferingId: invoice.thirdPartyClubRegistrationPersonOfferingId,
        thirdPartyClubRegistrationPersonId: invoice.thirdPartyClubRegistrationPersonId,
        thirdPartyClubRegistrationId: invoice.thirdPartyClubRegistrationId,
        memberId: invoice.memberId,
        firstName: invoice.firstName,
        lastName: invoice.lastName,
        middleName: invoice.middleName,
        preferredName: invoice.preferredName,
        birthDate: invoice.birthDate,
        competitionGenderTypeName: invoice.competitionGenderTypeName,
        emailAddress: invoice.emailAddress,
        submittedOfferingName: invoice.submittedOfferingName,
        offeringName: invoice.selectedOfferingName ? invoice.selectedOfferingName : invoice.offeringName,
        offeringInstanceId: invoice.selectedOfferingInstanceId ?
          (invoice.selectedOfferingInstanceId?.toString()?.includes('offeringInstanceCouponId') === true ? ToIntIfInt(invoice.selectedOfferingInstanceId.substring(0, invoice.selectedOfferingInstanceId.indexOf("o"))) : invoice.selectedOfferingInstanceId !== Constants.DEFAULT_ID ? invoice.selectedOfferingInstanceId : null) :
          invoice.offeringInstanceId,
        offeringInstanceCouponId: invoice.selectedOfferingInstanceId ?
          (invoice.selectedOfferingInstanceId?.toString()?.includes('offeringInstanceCouponId') === true ? ToIntIfInt(invoice.selectedOfferingInstanceId.substring(invoice.selectedOfferingInstanceId.indexOf("o"), invoice.selectedOfferingInstanceId.length)?.replace('offeringInstanceCouponId', '')) : null) :
          invoice.offeringInstanceCouponId,
        isClubOffering: invoice.isClubOffering
      })
    })
    putThirdPartyInvoiceByThirdPartyClubRegistrationIdData(baseUsasClubState.objData.orgUnitId, modalState?.invoice?.thirdPartyClubRegistrationId,
      thirdPartyInvoiceArray);
    setState({ ...state, tryErrorCheck: true });
  };

  const onCheckOutConfirmationContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setCheckOutConfirmationModalState(INITIAL_CHECK_OUT_CONFIRMATION_MODAL_STATE);
    //Navigate to cart
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  };

  const onCheckOutConfirmationModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setCheckOutConfirmationModalState(INITIAL_CHECK_OUT_CONFIRMATION_MODAL_STATE);
    //Navigate back
    onCancelClicked();
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true && modalState && modalState?.invoice) {
      getThirdPartyInvoiceByThirdPartyClubRegistrationIdData(baseUsasClubState.objData.orgUnitId,
        modalState?.invoice?.thirdPartyClubRegistrationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState]);

  useEffect(() => {
    if (clubThirdPartyInvoiceState.isArrayLoaded === true && clubThirdPartyInvoiceState.isArrayLoading === false) {
      const options = [...new Map(clubThirdPartyInvoiceState.arrayData?.map(memberRegistration => [memberRegistration['submittedOfferingName'], memberRegistration])).values()];
      const optionsFormatted = options?.map((option, i) => ({ id: option.offeringInstanceId || i, name: option.submittedOfferingName }));
      const optionsFormattedSorted = optionsFormatted.sort((a, b) => compareAlphabetical(a['name'], b['name']));
      optionsFormattedSorted.unshift({ id: Constants.DEFAULT_ID, name: '--' });

      setOfferingOptions({ ...offeringOptions, options: optionsFormattedSorted });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubThirdPartyInvoiceState.isArrayLoaded, clubThirdPartyInvoiceState.isArrayLoading, clubThirdPartyInvoiceState.arrayData]);

  useEffect(() => {
    if (personPotentialMatchState.isSaved === true && personPotentialMatchState.isSaving === false && state.tryUpdateGridWithMemberId === true &&
      state.thirdPartyClubRegistrationPersonId !== '' && state.memberId !== '') {
      let clubThirdPartyInvoiceStateArrayDataCopy = JSON.parse(JSON.stringify(clubThirdPartyInvoiceState.arrayData));
      let oldInvoiceInfoIndex = clubThirdPartyInvoiceStateArrayDataCopy.findIndex(x => x.thirdPartyClubRegistrationPersonId === state.thirdPartyClubRegistrationPersonId);
      clubThirdPartyInvoiceStateArrayDataCopy[oldInvoiceInfoIndex] = {
        ...clubThirdPartyInvoiceStateArrayDataCopy[oldInvoiceInfoIndex],
        memberId: state.memberId, hasPossibleMatch: false, memberIdValid: true
      };
      setClubThirdPartyInvoiceState({ ...clubThirdPartyInvoiceState, arrayData: clubThirdPartyInvoiceStateArrayDataCopy });
      setState({ ...state, tryUpdateGridWithMemberId: false, thirdPartyClubRegistrationPersonId: '', memberId: '' });
      onPersonPotentialMatchModalCancelClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPotentialMatchState.isSaved, personPotentialMatchState.isSaving, state.tryUpdateGridWithMemberId, state.thirdPartyClubRegistrationPersonId, state.memberId]);

  useEffect(() => {
    if (clubThirdPartyInvoiceState.isArrayLoaded === true && clubThirdPartyInvoiceState.isArrayLoading === false && state.tryErrorCheck === true) {
      let missingSelectionError = false;
      let ageSelectionError = false;
      let duplicateOfferingForSamePersonError = false;

      if (clubThirdPartyInvoiceState.arrayData.find(x => x.offeringName === null)) {
        missingSelectionError = true;
      }

      for (let i = 0; i < clubThirdPartyInvoiceState.arrayData.length; i++) {
        let selectedOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.arrayData?.find((x) => x.offeringName === clubThirdPartyInvoiceState.arrayData[i].offeringName);
        //check to see if person's age falls outside the allowed age range of the selected offering
        if (selectedOffering) {
          if (clubThirdPartyInvoiceState.arrayData[i].age < selectedOffering?.ageStart ||
            clubThirdPartyInvoiceState.arrayData[i].age > selectedOffering?.ageEnd) {
            ageSelectionError = true;
          }
        }

        // don't consider the invoice offering, but check all other rows to make sure that 
        // they aren't person offerings dupes of the same offering types
        let selectedOfferingSamePersonDupe = clubThirdPartyInvoiceState.arrayData?.find((x) =>
          x.offeringName === clubThirdPartyInvoiceState.arrayData[i]?.offeringName &&
          x.thirdPartyClubRegistrationPersonOfferingId !== clubThirdPartyInvoiceState.arrayData[i].thirdPartyClubRegistrationPersonOfferingId &&
          x.thirdPartyClubRegistrationPersonId === clubThirdPartyInvoiceState.arrayData[i].thirdPartyClubRegistrationPersonId);

        if (selectedOfferingSamePersonDupe) {
          duplicateOfferingForSamePersonError = true;
        }
      }

      if (missingSelectionError === true || ageSelectionError === true || duplicateOfferingForSamePersonError === true ||
        clubThirdPartyInvoiceState.arrayData.find(x => x.hasOffering === true) !== undefined || clubThirdPartyInvoiceState.arrayData.find(x => x.hasDupOffering === true) !== undefined) {
        setState({ ...state, showError: true, tryErrorCheck: false });
      }
      else {
        setState({ ...state, showError: false, tryErrorCheck: false });
        setCheckOutConfirmationModalState({
          ...checkOutConfirmationModalState, displayPopUp: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubThirdPartyInvoiceState.isArrayLoaded, clubThirdPartyInvoiceState.isArrayLoading, clubThirdPartyInvoiceState.arrayData, state.tryErrorCheck]);

  useEffect(() => {
    if (clubThirdPartyInvoiceState.isArrayLoaded === true && clubThirdPartyInvoiceState.isArrayLoading === false && state.tryDelete === true) {
      setState({ ...state, tryDelete: false });
      const arrayDataCopy = JSON.parse(JSON.stringify(clubThirdPartyInvoiceState.arrayData));
      setState({ ...state, tryCloseEntireModal: arrayDataCopy.length === 1 ? true : false });
      onDeleteInvoice(deleteModalState.thirdPartyClubRegistrationPersonOfferingId)
      onModalCancelClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubThirdPartyInvoiceState.isArrayLoaded, clubThirdPartyInvoiceState.isArrayLoading, clubThirdPartyInvoiceState.arrayData, state.tryDelete]);

  useEffect(() => {
    if (clubThirdPartyInvoiceState.isSaved === true && modalState && modalState?.invoice && state.tryCloseEntireModal === false) {
      confirmThirdPartyInvoicesSave();
      getThirdPartyInvoiceByThirdPartyClubRegistrationIdData(baseUsasClubState.objData.orgUnitId,
        modalState?.invoice?.thirdPartyClubRegistrationId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubThirdPartyInvoiceState.isSaved]);

  useEffect(() => {
    if (clubThirdPartyInvoiceState.isSaved === true && modalState && modalState?.invoice && state.tryCloseEntireModal === true) {
      confirmThirdPartyInvoicesSave();
      setState({ ...state, tryCloseEntireModal: false });
      onCancelClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubThirdPartyInvoiceState.isSaved]);

  function getInitialDeleteModalState() {
    return {
      displayPopUp: false,
      thirdPartyClubRegistrationPersonOfferingId: Constants.DEFAULT_ID,
      name: '',
      competitionCategory: '',
      birthDate: '',
      emailAddress: '',
      offeringName: ''
    };
  }

  return {
    state,
    baseUsasClubState,
    isLoading: baseUsasClubState.isObjLoading || clubThirdPartyInvoiceState.isArrayLoading || personPotentialMatchState.isArrayLoading,
    isSaving: personPotentialMatchState.isSaving || clubThirdPartyInvoiceState.isSaving,
    clubThirdPartyInvoiceState,
    setClubThirdPartyInvoiceState,
    lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState,
    onDeleteClicked,
    gridState,
    setGridState,
    offeringOptions,
    personPotentialMatchState,
    personPotentialMatchModalState,
    onPersonPotentialMatchModalOpenClicked,
    onSelectMemberIdMatch,
    onPersonPotentialMatchModalSaveClicked,
    onPersonPotentialMatchModalCancelClicked,
    deleteModalState,
    onModalDeleteClicked,
    onModalCancelClicked,
    onCheckOutClicked,
    checkOutConfirmationModalState,
    onCheckOutConfirmationContinueClicked,
    onCheckOutConfirmationModalCanceled,
    onCancelClicked,
    onUpdateSelectedOfferingNameValueChange
  };
};

export default useClubThirdPartyInvoicesDetail;