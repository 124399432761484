/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../components/icons/EditIcon';
import DeleteIcon from '../../components/icons/DeleteIcon';

import global from '../../components/GlobalStyle.module.css';
import { formatDate, formatDateTimeToTimeTwelveHourClock } from '../../utils/DateFunctions';

const LargeGrid = ({ showOptionalFields, showOtsField, sortedMeetSessionArray, onEdit, onDelete, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Session Number</th>
        <th>Session Type</th>
        <th>Session Date</th>
        {showOptionalFields === true &&
          <Fragment>
            <th>Warm Up Time</th>
            <th>Start Time</th>
          </Fragment>
        }
        {showOtsField === true &&
          <Fragment>
            <th>OQM Session?</th>
          </Fragment>}
        <th></th>
      </tr>
    </thead>
    <tbody>
      {sortedMeetSessionArray?.length > 0 ?
        sortedMeetSessionArray.map((session, i) => (
          < tr key={session.meetSessionId || i} >
            <td>{session.sessionOrderId >= 0 ? session.sessionOrderId : ''}</td>
            <td>{session.sessionType?.typeName ? session.sessionType?.typeName : ''}</td>
            <td>{session.sessionDate ? formatDate(session.sessionDate) : ''}</td>
            {showOptionalFields === true &&
              <Fragment>
                <td>{session.warmUpTime ? formatDateTimeToTimeTwelveHourClock(session.warmUpTime) : ''}</td>
                <td>{session.startTime ? formatDateTimeToTimeTwelveHourClock(session.startTime) : ''}</td>
              </Fragment>
            }
            {showOtsField === true &&
              <Fragment>
                <td>{session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : ''}</td>
              </Fragment>
            }
            <td>
              {onEdit &&
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, session)}>
                  <EditIcon />
                </button>}
              {onDelete && sortedMeetSessionArray.length > 1 &&
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteClicked(e, session)}>
                  <DeleteIcon />
                </button>}
            </td>
          </tr>)
        ) : (
          <tr>
            {showOptionalFields === true && showOtsField === true ?
              <td colSpan="7">No Sessions</td> :
              showOptionalFields === true && showOtsField !== true ?
                <td colSpan="6">No Sessions</td> :
                showOptionalFields !== true && showOtsField === true ?
                  <td colSpan="5">No Sessions</td> :
                  <td colSpan="4">No Sessions</td>}
          </tr>
        )
      }
    </tbody>
  </table >
);

const SmallGrid = ({ showOptionalFields, showOtsField, sortedMeetSessionArray, onEdit, onDelete, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {sortedMeetSessionArray?.length > 0 ?
      sortedMeetSessionArray.map((session, i) => (
        <div className={global.SmallTableRow} key={session.meetSessionId || i}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>Session Number {session.sessionOrderId}</span>&nbsp;
            {onDelete && sortedMeetSessionArray.length > 1 &&
              <button className={global.IconButton} type="button" onClick={(e) => onDeleteClicked(e, session)}>
                <DeleteIcon />
              </button>}
            {onEdit &&
              <button className={onDelete && sortedMeetSessionArray.length > 1 ? global.IconButtonMobileMargin : global.IconButton} type="button" onClick={(e) => onEdit(e, session)}>
                <EditIcon />
              </button>}
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Session Number</div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{session.sessionOrderId >= 0 ? session.sessionOrderId : <span>&nbsp;</span>}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Session Type </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.sessionType?.typeName ? session.sessionType?.typeName : <span>&nbsp;</span>}</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Session Date</div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.sessionDate ? formatDate(session.sessionDate) : <span>&nbsp;</span>}</div>
              {showOptionalFields === true &&
                <Fragment>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Warm Up Time</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.warmUpTime ? formatDateTimeToTimeTwelveHourClock(session.warmUpTime) : <span>&nbsp;</span>}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Start Time</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.startTime ? formatDateTimeToTimeTwelveHourClock(session.startTime) : <span>&nbsp;</span>}</div>
                </Fragment>}
              {showOtsField === true &&
                <Fragment>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>OQM Session?</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : <span>&nbsp;</span>}</div>
                </Fragment>}
            </div>
          </div>
        </div>
      )
      )
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Sessions</div>
          </div>
        </div>
      )}
  </div >
);

const SessionsGrid = ({ showOptionalFields, showOtsField, sortedMeetSessionArray, onEdit, onDelete, onDeleteClicked }) => (
  <Fragment>
    <LargeGrid showOptionalFields={showOptionalFields} showOtsField={showOtsField} sortedMeetSessionArray={sortedMeetSessionArray} onEdit={onEdit} onDelete={onDelete} onDeleteClicked={onDeleteClicked} />
    <SmallGrid showOptionalFields={showOptionalFields} showOtsField={showOtsField} sortedMeetSessionArray={sortedMeetSessionArray} onEdit={onEdit} onDelete={onDelete} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default SessionsGrid;