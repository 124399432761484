export const navLinks = {
  ROOT: '/memberadministration',
  HOME: '/memberadministration/home',
  LSC_SELECTION: '/memberadministration/selection',
  LSC_BOARD: '/memberadministration/lscboardofdirectors',
  LSC_BOARD_ADD: '/memberadministration/lscboardofdirectors/add',
  LSC_BOARD_EDIT: '/memberadministration/lscboardofdirectors/edit',
  LSC_STAFF: '/memberadministration/lscstaff',
  LSC_STAFF_ADD: '/memberadministration/lscstaff/add',
  LSC_STAFF_EDIT: '/memberadministration/lscstaff/edit',
  LSC_EMAILS: '/memberadministration/lscemails',
  LSC_EMAILS_ADD: '/memberadministration/lscemails/add',
  LSC_EMAILS_EDIT: '/memberadministration/lscemails/edit',
  LSC_REG_MANAGEMENT: '/memberadministration/lscregistrationmanagement',
  LSC_SECURITY_ROLES: '/memberadministration/lscsecurityroles',
  LSC_SECURITY_ROLES_ADD: '/memberadministration/lscsecurityroles/add',
  LSC_SECURITY_ROLES_EDIT: '/memberadministration/lscsecurityroles/edit'
};