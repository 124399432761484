import { Fragment } from "react";

import useProviderInfoForm from "./UseProviderInfoForm";

import { getUploadedFileName } from "../../utils/LearnToSwimUtils";

import LearnToSwimCurriculumDropdown from "../../../../components/dropdowns/learnToSwimCurriculumDropdown/LearnToSwimCurriculumDropdown";
import LearnToSwimClubsCombobox from "../../../../components/comboboxes/learnToSwimClubsCombobox/LearnToSwimClubsCombobox";

import Input from "../../../../../common/components/inputs/Input";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';
import Headings from "../../../../../common/components/headings/Headings";

const ProviderInfoForm = ({ submitButtonText = 'Save', providerObj, onSubmitFormCallback }) => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    resetForm,
    onFileLoad,
    onFileUnload,
    onFileDownload
  } = useProviderInfoForm(onSubmitFormCallback, providerObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Business Legal Name*'
              name="entityName"
              value={formState.entityName}
              error={errorState.entityName}
              message={errorState.entityName}
              onChange={(value) => { updateFormState('entityName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Trade Name/DBA*'
              name="tradeName"
              value={formState.tradeName}
              error={errorState.tradeName}
              message={errorState.tradeName}
              onChange={(value) => { updateFormState('tradeName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Tax Id*'
              name="taxId"
              value={formState.taxId}
              error={errorState.taxId}
              message={errorState.taxId}
              onChange={(value) => { updateFormState('taxId', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label='Website*'
              name="website"
              value={formState.website}
              error={errorState.website}
              message={errorState.website}
              onChange={(value) => { updateFormState('website', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <LearnToSwimCurriculumDropdown
              label='Learn To Swim Curriculum*'
              name="learnToSwimCurriculumId"
              value={formState.learnToSwimCurriculumId}
              error={errorState.learnToSwimCurriculumId}
              message={errorState.learnToSwimCurriculumId}
              onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'learnToSwimCurriculumId', valueLabel, 'learnToSwimCurriculumName') }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label='Offers Financial Assistance?'
              name="offersFinancialAssistance"
              checked={formState.offersFinancialAssistance}
              error={errorState.offersFinancialAssistance}
              message={errorState.offersFinancialAssistance}
              onChange={(value) => { updateFormState('offersFinancialAssistance', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label='Is A Current Year Learn To Swim Grant Recipient?'
              name="currentLTSGrantRecipient"
              checked={formState.currentLTSGrantRecipient}
              error={errorState.currentLTSGrantRecipient}
              message={errorState.currentLTSGrantRecipient}
              onChange={(value) => { updateFormState('currentLTSGrantRecipient', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label='Is this Program operated by a USA Swimming Club?'
              name="affiliatedWithUsasClub"
              checked={formState.affiliatedWithUsasClub}
              error={errorState.affiliatedWithUsasClub}
              message={errorState.affiliatedWithUsasClub}
              onChange={(value) => { updateFormState('affiliatedWithUsasClub', value) }} />
          </div>
          <div className={formState.affiliatedWithUsasClub === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <LearnToSwimClubsCombobox
                label='Affiliated USA Swimming Club*'
                name="usasClubId"
                valueToMatch={formState.usasClubName}
                error={errorState.usasClubId}
                message={errorState.usasClubId}
                onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'usasClubId', valueLabel, 'usasClubName') }} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <Headings.H5>Logo</Headings.H5>
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            {formState.logoFile?.size > 0 || formState.logoUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {formState.logoUrl !== '' && <PrimaryButton type="button" onClick={(e) => onFileDownload(e)}>Download Uploaded File</PrimaryButton>}
                  <p><b>File:</b> {formState.logoFile.name || getUploadedFileName(formState.logoUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="logo-file-upload" className={global.FileUpload}>Upload Logo</label>
                  <input hidden id="logo-file-upload" type="file" onChange={(e) => onFileLoad(e)} />&nbsp;(*.jpg)
                  {errorState.logoFile && <p className={global.ErrorMessage}>{errorState.logoFile}</p>}
                </Fragment>
              )
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ProviderInfoForm;