import { Fragment } from "react";

import useRegistrationProviderInfoForm from "./UseRegistrationProviderInfoForm";

import LearnToSwimCurriculumDropdown from "../../../../components/dropdowns/learnToSwimCurriculumDropdown/LearnToSwimCurriculumDropdown";
import LearnToSwimClubsCombobox from "../../../../components/comboboxes/learnToSwimClubsCombobox/LearnToSwimClubsCombobox";

import Input from "../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const RegistrationProviderInfoForm = ({ submitButtonText = 'Save & Continue', secondaryButtonText = 'Back',
  providerObj, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  } = useRegistrationProviderInfoForm(onSubmitFormCallback, providerObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Business Legal Name'
              name="entityName"
              value={formState.entityName} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Trade Name/DBA'
              name="tradeName"
              value={formState.tradeName} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ReadOnly
              label='Tax Id'
              name="taxId"
              value={formState.taxId} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <LearnToSwimCurriculumDropdown
              label='Learn To Swim Curriculum*'
              name="learnToSwimCurriculumId"
              value={formState.learnToSwimCurriculumId}
              error={errorState.learnToSwimCurriculumId}
              message={errorState.learnToSwimCurriculumId}
              onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'learnToSwimCurriculumId', valueLabel, 'learnToSwimCurriculumName') }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input
              label='Website*'
              name="website"
              value={formState.website}
              error={errorState.website}
              message={errorState.website}
              onChange={(value) => { updateFormState('website', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Offers Financial Assistance?'
              name="offersFinancialAssistance"
              checked={formState.offersFinancialAssistance}
              error={errorState.offersFinancialAssistance}
              message={errorState.offersFinancialAssistance}
              onChange={(value) => { updateFormState('offersFinancialAssistance', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is A Current Year Learn To Swim Grant Recipient?'
              name="currentLTSGrantRecipient"
              checked={formState.currentLTSGrantRecipient}
              error={errorState.currentLTSGrantRecipient}
              message={errorState.currentLTSGrantRecipient}
              onChange={(value) => { updateFormState('currentLTSGrantRecipient', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <YesNoSwitch
              label='Is this Program operated by a USA Swimming Club?'
              name="affiliatedWithUsasClub"
              checked={formState.affiliatedWithUsasClub}
              error={errorState.affiliatedWithUsasClub}
              message={errorState.affiliatedWithUsasClub}
              onChange={(value) => { updateFormState('affiliatedWithUsasClub', value) }} />
          </div>
          <div className={formState.affiliatedWithUsasClub === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6">
              <LearnToSwimClubsCombobox
                label='Affiliated USA Swimming Club*'
                name="usasClubId"
                valueToMatch={formState.usasClubName}
                error={errorState.usasClubId}
                message={errorState.usasClubId}
                onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'usasClubId', valueLabel, 'usasClubName') }} />
            </div>
          </div>
        </div>
        <div className="row pull-right">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>&nbsp;
            <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default RegistrationProviderInfoForm;